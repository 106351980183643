import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios'; 
import moment from 'moment';

// Define API URL
const API_URL = process.env.REACT_APP_API_URL;

const CombinedCharts = () => {
  const [patientStartDate, setPatientStartDate] = useState(moment().subtract(1, 'week').format('DD-MM-YYYY'));
  const [patientEndDate, setPatientEndDate] = useState(moment().format('DD-MM-YYYY'));
  const [statusPatient, setStatusPatient] = useState('pending');
  const [patientData, setPatientData] = useState([]);

  const [doctorStartDate, setDoctorStartDate] = useState(moment().subtract(1, 'week').format('DD-MM-YYYY'));
  const [doctorEndDate, setDoctorEndDate] = useState(moment().format('DD-MM-YYYY'));
  const [statusDoctor, setStatusDoctor] = useState('pending');
  const [doctorData, setDoctorData] = useState([]);

  const fetchPatientData = async () => {
    try {
      const response = await axios.post(`${API_URL}/getGraphOfAllDoctorAndPatientAppointments`, {
        startDate: patientStartDate,
        endDate: patientEndDate,
        status: statusPatient,
      });
      setPatientData(response.data.patientData);
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const fetchDoctorData = async () => {
    try {
      const response = await axios.post(`${API_URL}/getGraphOfAllDoctorAndPatientAppointments`, {
        startDate: doctorStartDate,
        endDate: doctorEndDate,
        status: statusDoctor,
      });
      setDoctorData(response.data.doctorData);
    } catch (error) {
      console.error('Error fetching doctor data:', error);
    }
  };

  useEffect(() => {
    fetchPatientData();
  }, [patientStartDate, patientEndDate, statusPatient]); 

  useEffect(() => {
    fetchDoctorData();
  }, [doctorStartDate, doctorEndDate, statusDoctor]);

  return (
    <div className=" min-h-screen">
   
      <div>
        <div className="flex-1 bg-white shadow-lg rounded-lg p-4 mb-4 md:mb-0">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Client Appointments</h2>
          <div className="flex justify-between items-center mb-4">
            <div className="flex space-x-2">
              <button
                className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition"
                onClick={() => {
                  const newStartDate = moment(patientStartDate, "DD-MM-YYYY").subtract(7, 'days').format('DD-MM-YYYY');
                  setPatientStartDate(newStartDate);
                  setPatientEndDate(moment(newStartDate, "DD-MM-YYYY").add(6, 'days').format('DD-MM-YYYY'));
                }}>
                Previous Week
              </button>
              <button
                className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition"
                onClick={() => {
                  const newStartDate = moment(patientStartDate, "DD-MM-YYYY").add(7, 'days').format('DD-MM-YYYY');
                  setPatientStartDate(newStartDate);
                  setPatientEndDate(moment(newStartDate, "DD-MM-YYYY").add(6, 'days').format('DD-MM-YYYY'));
                }}>
                Next Week
              </button>
            </div>
            <select
  value={statusPatient}
  onChange={(e) => setStatusPatient(e.target.value)}
  className="p-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-[250px]">
  <option value="pending">Pending</option>
  <option value="completed">Completed</option>
</select>

          </div>

          <div className="text-gray-700 mb-4">
            Showing data from <strong>{moment(patientStartDate, "DD-MM-YYYY").format('MMMM D, YYYY')}</strong> to <strong>{moment(patientEndDate, "DD-MM-YYYY").format('MMMM D, YYYY')}</strong>
          </div>

          <Chart
            options={{
              chart: {
                id: 'patients',
                toolbar: { show: false },
              },
              xaxis: {
                categories: patientData.map((data) => data.day),
                labels: { style: { colors: '#000', fontSize: '12px' } },
              },
              colors: ['#008FFB'],
              dataLabels: { enabled: false },
              plotOptions: {
                bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' },
              },
            }}
            series={[{ name: 'Patients', data: patientData.map((data) => data.count) }]}
            type="bar"
            height={350}
          />
        </div>

        
        <div className="flex-1 bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Doctor Appointments</h2>
          <div className="flex justify-between items-center mb-4">
            <div className="flex space-x-2">
              <button
                className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition"
                onClick={() => {
                  const newStartDate = moment(doctorStartDate, "DD-MM-YYYY").subtract(7, 'days').format('DD-MM-YYYY');
                  setDoctorStartDate(newStartDate);
                  setDoctorEndDate(moment(newStartDate, "DD-MM-YYYY").add(6, 'days').format('DD-MM-YYYY'));
                }}>
                Previous Week
              </button>
              <button
                className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition"
                onClick={() => {
                  const newStartDate = moment(doctorStartDate, "DD-MM-YYYY").add(7, 'days').format('DD-MM-YYYY');
                  setDoctorStartDate(newStartDate);
                  setDoctorEndDate(moment(newStartDate, "DD-MM-YYYY").add(6, 'days').format('DD-MM-YYYY'));
                }}>
                Next Week
              </button>
            </div>
            <select
              value={statusDoctor}
              onChange={(e) => setStatusDoctor(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-[250px]">
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="ignored">Ignored</option>
              <option value="accepted">Accepted</option>
            </select>
          </div>

          <div className="text-gray-700 mb-4">
            Showing data from <strong>{moment(doctorStartDate, "DD-MM-YYYY").format('MMMM D, YYYY')}</strong> to <strong>{moment(doctorEndDate, "DD-MM-YYYY").format('MMMM D, YYYY')}</strong>
          </div>

          <Chart
            options={{
              chart: {
                id: 'doctors',
                toolbar: { show: false },
              },
              xaxis: {
                categories: doctorData.map((data) => data.day),
                labels: { style: { colors: '#000', fontSize: '12px' } },
              },
              colors: ['#FF4560'],
              dataLabels: { enabled: false },
              plotOptions: {
                bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' },
              },
            }}
            series={[{ name: 'Doctors', data: doctorData.map((data) => data.count) }]}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default CombinedCharts;
