import React, { useState, useEffect } from "react";
// import { FaExternalLinkAlt } from "react-icons/fa"; // Import "Open Web" icon
import "./FAQListing.css";

const API_URL = process.env.REACT_APP_API_URL;

const FAQWebView = () => {
  const [faqs, setFAQ] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    const fetchFAQ = async () => {
      const response = await fetch(`${API_URL}/getAllFAQ`);
      const data = await response.json();
      console.log(data, "Fetched FAQ");

      setFAQ(data?.data || []);
    };

    fetchFAQ();
  }, []);

  return (
    <div className="blog-listing">
      <div className="blog-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {faqs.length > 0 ? (
          <div className="w-full sm:w-3/4 mx-auto mt-10 border border-gray-400 rounded-lg shadow-lg overflow-hidden p-2 sm:p-4">
            {faqs.map((item, index) => (
              <div key={index} className="border-b border-gray-300">
                <button
                  onClick={() =>
                    setOpenIndex(openIndex === index ? null : index)
                  }
                  className="w-full text-left p-4 flex justify-between items-center text-black font-semibold focus:outline-none"
                >
                  {`${item.question}`}
                  <span className="text-gray-500">
                    {openIndex === index ? "▲" : "▼"}
                  </span>
                </button>
                {openIndex === index && (
                  <div className="p-4 text-gray-600 text-sm sm:text-base">
                    {`${item.answer}`}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-600 text-center">Loading FAQs...</p>
        )}
      </div>
    </div>
  );
};

export default FAQWebView;
