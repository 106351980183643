import React, { useEffect, useState } from 'react';
import Table from '../../components/commonComponent/Table';
import Pagination from "../../components/commonComponent/Pagination";
import useModal from "../../hooks/useModal";
import { deleteData, getData, getCurrentData, handlePageChange } from "../../utils/utils";
import DeleteConfirmationModal from '../../components/commonComponent/DeleteConfirmationModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ViewModal from '../../components/commonComponent/ViewModal';
import { toast } from 'react-toastify';
import Search from '../../components/commonComponent/Search';
import DateRangePicker from '../../components/commonComponent/DateRangePicker';
import { isWithinInterval, parse } from 'date-fns'; 
import { therapistSchema } from '../../utils/validationSchema';
import Filter from '../../components/commonComponent/Filter';

const API_URL = process.env.REACT_APP_API_URL;

const ITEMS_PER_PAGE = 10;

const columns = [
  { header: 'Therapist Email', accessor: 'therapistEmail' },
  { header: 'Patient Email', accessor: 'patientEmail' },
  { header: 'Appointment Date', accessor: 'date' },
  { header: 'Status', accessor: 'status' },
];

const DocAppointment = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const location = useLocation();


  const navigate = useNavigate();
  const {
    showDeleteModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenViewModal,
    handleCloseViewModal
  } = useModal();

  useEffect(() => {
    const processData = (appointments) => {
      return appointments.map(appointment => ({
        ...appointment,
        therapistEmail: appointment.receiverId?.email || 'N/A',
        patientEmail: appointment.senderId?.email || 'N/A',
        therapistFirstName: appointment.receiverId?.firstName || 'N/A',
        therapistLastName: appointment.receiverId?.lastName || 'N/A',
        appointmentDate: new Date(appointment.date),
      }));
    };

    getData(`${API_URL}/getAllAppointments`, setData, setError, processData, setFilteredData, setLoading);
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = data.filter((appointment) => {
      const therapistEmail = appointment.therapistEmail || '';
      const patientEmail = appointment.patientEmail || '';
      const therapistFirstName = appointment.therapistFirstName || '';
      const therapistLastName = appointment.therapistLastName || '';
      const patientFirstName = appointment.patientFirstName || '';
      const patientLastName = appointment.patientLastName || '';



      return (
        therapistEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patientEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        therapistFirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        therapistLastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patientFirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patientLastName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setCurrentPage(1); 
  };


  
        const handleFilterChange = (selectedFilters) => {
          setFilters(selectedFilters);
      
          const { Status = [] } = selectedFilters;
      
          const filtered = data.filter((therapist) => {
            const statusMatch = Status.length === 0 || Status.includes(therapist.status);
            return statusMatch;
          });
      
          setFilteredData(filtered);
          setCurrentPage(1);
        };

        const handleDateFilter = (startDate, endDate) => {
          if (!startDate || !endDate) {
            setFilteredData(data);
            return;
          }
        
          const filtered = data.filter((appointment) => {
            const appointmentDate = parse(appointment.date, 'dd-MM-yyyy', new Date());
        
            return isWithinInterval(appointmentDate, { start: startDate, end: endDate });
          });
        
          setFilteredData(filtered);
          setCurrentPage(1); 
        };
        const handleDeleteConfirm = () => {
          deleteData(`${API_URL}/deleteAppointment`, selectedItem._id, setError)
            .then(() => {
              // Remove the deleted item from the data and filteredData
              setData((prevData) => prevData.filter((item) => item._id !== selectedItem._id));
              setFilteredData((prevFilteredData) =>
                prevFilteredData.filter((item) => item._id !== selectedItem._id)
              );
              
              toast.success('Deleted successfully', { autoClose: 2000 });
            })
            .catch((error) => {
              setError(error);
              toast.error('Error deleting data', { autoClose: 2000 });
            });
        
          handleCloseDeleteModal(); // Close the delete confirmation modal
        };
        
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(filteredData, currentPage, ITEMS_PER_PAGE);

  const fieldsToShow = {
    'Therapist First Name': 'receiverId.firstName',
    'Therapist Last Name': 'receiverId.lastName',
    'Therapist Email': 'receiverId.email',
    'Patient First Name': 'senderId.firstName',
    'Patient Last Name': 'senderId.lastName',
    'Patient Email': 'senderId.email',
    'Appointment Date': 'date',
    'Status': 'status',
  };

    const status = [...new Set(data.map((therapist) => therapist.status))];

    const filterOptions = {
      Status: status,
      
    };

  return (
    <div className="therapist-page">
      <div className="flex justify-between items-center text-[#1B1F2E] px-0 py-4 mb-2">
        <h2 className='text-[28px] font-semibold '>Appointment List</h2>
      </div>
      <div className="search-filter-container shadow-sm  justify-between  gap-5 mb-3 flex-wrap bg-white rounded-lg px-3 py-3 flex items-start min-h-[170px] h-[170px]">
        <Search
          searchFields={["Therapist Email", "Patient Email", "Name"]}
          onSearch={handleSearch}
        />
                <Filter filterOptions={filterOptions} onFilterChange={handleFilterChange} />

        <DateRangePicker onDateChange={handleDateFilter} />
      </div>
      <div className='table-container'>
        <Table
          columns={columns}
          data={currentData}
          showIndex={true}
          currentPage={currentPage}
          loading={loading}
          itemsPerPage={ITEMS_PER_PAGE}
          onDelete={handleOpenDeleteModal}
          onUpdate={(item) => navigate(`/doc-appointment/edit/${item._id}`)}
          onView={handleOpenViewModal}
          showEdit={false}
          showForm={false}
        />
           {!loading && (
  <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    onPageChange={handlePageChange(setCurrentPage)}
  />
)}

      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      {selectedItem && (
        <ViewModal
          show={showViewModal}
          onHide={handleCloseViewModal}
          data={selectedItem}
          fields={fieldsToShow}
        />
      )}
    </div>
  );
};

export default DocAppointment;
