// src/router/AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TherapistFormProvider } from "../context/FormContext";
import { registrationSchema, loginSchema } from "../utils/validationSchema";
import Layout from "../components/Layout";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import Therapist from "../pages/therapist/Therapist";
import Patient from "../pages/patient/Patient";
import DocAppointment from "../pages/appointment/DocAppointment";
import Notification from "../pages/notification/Notification";
import { AuthProvider } from "../context/AuthContext";
import PublicRoute from "./publicRoutes";
import PrivateRoute from "./privateRoutes";
import TherapistAdd from "../pages/therapist/TherapistAdd";
import TherapistUpdate from "../pages/therapist/TherapistUpdate";
import PatientAdd from "../pages/patient/PatientAdd";
import PatientUpdate from "../pages/patient/PatientUpdate";
import AppointmentAdd from "../pages/appointment/AppointmentAdd";
import AppointmentUpdate from "../pages/appointment/AppointmentUpdate";
import Questionnare from "../pages/questionnare/Questionnare";
import Profile from "../pages/profile/Profile";
import AddQuestionnare from "../pages/questionnare/AddQuestionnare";
import UpdateQuestionnare from "../pages/questionnare/UpdateQuestionnare";
import Blog from "../pages/blogs/Blog";
import BlogEditor from "../pages/blogs/BlogEditor";
import BlogAdd from "../pages/blogs/BlogAdd";
import BlogWebView from "../pages/blogs/BlogWebView";
import BlogDetails from "../pages/blogs/BlogDetail";
import Webinars from "../pages/webinars/Webinars";
import WebinarsAdd from "../pages/webinars/WebinarsAdd";
import WebinarWebView from "../pages/webinars/WebinarWebView";
import WebinarDetails from "../pages/webinars/WebinarDetail";
import Podcast from "../pages/podcast/Podcast";
import PodcastAdd from "../pages/podcast/PodcastAdd";
import PodcastWebView from "../pages/podcast/PodcastWebView";
import PodcastDetails from "../pages/podcast/PodcastDetails";
import SportPsychologyTraining from "../pages/Sport Psychology Training/SportPsychologyTraining";
import SportPsychologyTrainingAdd from "../pages/Sport Psychology Training/SportPsychologyTrainingAdd";
import SportPsychologyTrainingView from "../pages/Sport Psychology Training/SportPsychologyTrainingView";
import SportPsychologyTrainingDetail from "../pages/Sport Psychology Training/SportPsychologyTrainingDetail";
import Store from "../pages/online Store/Store";
import StoreDetails from "../pages/online Store/StoreDetails";
import StoreWebView from "../pages/online Store/StoreWebView";
import StoresAdd from "../pages/online Store/StoresAdd";
import Legals from "../pages/legal/Legals";
import LegalDetail from "../pages/legal/LegalDetail";
import LegalWebView from "../pages/legal/LegalWebView";
import LegalsAdd from "../pages/legal/LegalsAdd";
import FAQ from "../pages/FAQ/FAQ";
import FAQAdd from "../pages/FAQ/FAQAdd";
import FAQDetail from "../pages/FAQ/FAQDetail";
import FAQWebView from "../pages/FAQ/FAQWebView";
import InTheNews from "../pages/inthenews/InTheNews";
import InTheNewsAdd from "../pages/inthenews/InTheNewsAdd";
import InTheNewsDetail from "../pages/inthenews/InTheNewsDetail";
import InTheNewsWebView from "../pages/inthenews/InTheNewsWebView";

const AppRouter = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <TherapistFormProvider schema={loginSchema}>
                  <Login />
                </TherapistFormProvider>
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <TherapistFormProvider schema={loginSchema}>
                  <Login />
                </TherapistFormProvider>
              </PublicRoute>
            }
          />
          <Route
            path="/blogswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <BlogWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <BlogDetails />
              </TherapistFormProvider>
            }
          />

          <Route
            path="/register"
            element={
              <PublicRoute>
                <TherapistFormProvider schema={registrationSchema}>
                  <Register />
                </TherapistFormProvider>
              </PublicRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Layout>
                  <Profile />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/therapist"
            element={
              <PrivateRoute>
                <Layout>
                  <Therapist />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/therapist/add"
            element={
              <PrivateRoute>
                <Layout>
                  <TherapistAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/therapist/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <TherapistUpdate />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient"
            element={
              <PrivateRoute>
                <Layout>
                  <Patient />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/add"
            element={
              <PrivateRoute>
                <Layout>
                  <PatientAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <PatientUpdate />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route
            path="/doc-appointment/add"
            element={
              <PrivateRoute>
                <Layout>
                  <AppointmentAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/doc-appointment/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <AppointmentUpdate />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/doc-appointment"
            element={
              <PrivateRoute>
                <Layout>
                  <DocAppointment />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <PrivateRoute>
                <Layout>
                  <Notification />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/questionnaire"
            element={
              <PrivateRoute>
                <Layout>
                  <Questionnare />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/questionnare-add"
            element={
              <PrivateRoute>
                <Layout>
                  <AddQuestionnare />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/questionnare-update/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <UpdateQuestionnare />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/blogs"
            element={
              <PrivateRoute>
                <Layout>
                  <Blog />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/webinars"
            element={
              <PrivateRoute>
                <Layout>
                  <Webinars />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/webinarswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <WebinarWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/webinar/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <WebinarDetails />
              </TherapistFormProvider>
            }
          />
          {/**/}
          <Route
            path="/sportpsychologytrainings"
            element={
              <PrivateRoute>
                <Layout>
                  <SportPsychologyTraining />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sportspsychologytrainingswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <SportPsychologyTrainingView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/sportpsychologytraining/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <SportPsychologyTrainingDetail />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/stores"
            element={
              <PrivateRoute>
                <Layout>
                  <Store />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/onlinestoreswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <StoreWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/store/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <StoreDetails />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/legals"
            element={
              <PrivateRoute>
                <Layout>
                  <Legals />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/legalswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <LegalWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/legal/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <LegalDetail />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/faq"
            element={
              <PrivateRoute>
                <Layout>
                  <FAQ />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/faqswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <FAQWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/faq/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <FAQDetail />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/inthenews"
            element={
              <PrivateRoute>
                <Layout>
                  <InTheNews />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/inthenewswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <InTheNewsWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/inthenews/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <InTheNewsDetail />
              </TherapistFormProvider>
            }
          />
          {/* */}
          <Route
            path="/blogs-add"
            element={
              <PrivateRoute>
                <Layout>
                  <BlogAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/webinars-add"
            element={
              <PrivateRoute>
                <Layout>
                  <WebinarsAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sportpsychologytraining-add"
            element={
              <PrivateRoute>
                <Layout>
                  <SportPsychologyTrainingAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/store-add"
            element={
              <PrivateRoute>
                <Layout>
                  <StoresAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/legals-add"
            element={
              <PrivateRoute>
                <Layout>
                  <LegalsAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/faq-add"
            element={
              <PrivateRoute>
                <Layout>
                  <FAQAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/inthenews-add"
            element={
              <PrivateRoute>
                <Layout>
                  <InTheNewsAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/blogs-add"
            element={
              <PrivateRoute>
                <Layout>
                  <BlogEditor/>
                </Layout>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/blogs/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <BlogAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/webinars/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <WebinarsAdd />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route
            path="/sportpsychologytraining/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <SportPsychologyTrainingAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/stores/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <StoresAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/legals/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <LegalsAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/faq/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <FAQAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/inthenews/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <InTheNewsAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/podcast"
            element={
              <PrivateRoute>
                <Layout>
                  <Podcast />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/podcast-add"
            element={
              <PrivateRoute>
                <Layout>
                  <PodcastAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/podcast/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <PodcastAdd />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/podcastswebview"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <PodcastWebView />
              </TherapistFormProvider>
            }
          />
          <Route
            path="/podcast/:id"
            element={
              <TherapistFormProvider schema={loginSchema}>
                <PodcastDetails />
              </TherapistFormProvider>
            }
          />
          {/*        
              <Route
            path="/blogswebview"
            element={
                <Layout>
                  <BlogWebView/>
                </Layout>
            }
          /> */}
          {/* Add more routes here */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default AppRouter;
