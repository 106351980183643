import React, { useEffect, useState } from "react";
import axios from "axios";
import Form from "../../components/commonComponent/Form"; // Adjust path as needed
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { therapistSchema } from "../../utils/validationSchema";

const API_URL = process.env.REACT_APP_API_URL;

const fields = [
  {
    label: "First Name",
    name: "firstName",
    placeholder: "Enter first name",
    required: true,
  },
  { label: "Last Name", name: "lastName", placeholder: "Enter last name" },
  {
    label: "Email",
    name: "email",
    placeholder: "Enter email",
    type: "email",
    required: true,
  },
  {
    label: "State",
    name: "state",
    placeholder: "Select state",
    type: "dropdown",
    required: true,
  },
  {
    label: "City",
    name: "city",
    placeholder: "Select city",
    type: "dropdown",
    required: true,
  },
  { label: "Password", name: "password", placeholder: "Enter Password" },
];

const DoctorAdd = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(therapistSchema),
  });

  const [states, setStates] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  // Fetch states from API
  const fetchStates = async () => {
    try {
      const response = await axios.get(`${API_URL}/india`);
      setStates(response.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

 

  const handleStateChange = async (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setValue("state", state); // Update form state for validation
    clearErrors("state"); // Clear errors for the state field


    if (state) {
      try {
        const response = await axios.get(`${API_URL}/india/${state}`);
        const cities = response.data;
        console.log("Fetched cities:", cities); // Log the fetched cities
        setCities(cities || []); // Ensure it falls back to an empty array if no cities found
        setValue("city", ""); // Reset city when state changes
      } catch (error) {
        console.error("Error fetching cities:", error);
        setCities([]);
        setValue("city", ""); // Reset the selected city
      }
    } else {
      setCities([]);
      setValue("city", ""); // Reset the selected city
    }
  };
  const handleCityChange = (event) => {
    const city = event.target.value;
    setValue("city", city); // Set the value of the city field in the form
    clearErrors("city"); // Clear errors for the city field

  };

  const onSubmit = async (formData) => {
    try {
      const result = await addDoctor({
        ...formData,
        city: formData.city, // Use form data directly for city
        state: selectedState, // Ensure you're sending the selected state
      });
      toast.success("Data added successfully", {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/therapist");
      }, 2000);
    } catch (error) {
      const errorMessage = error.error || 'Error adding data';
      toast.error(errorMessage, {
        autoClose: 2000,
      });
    }
  };

  const addDoctor = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/signupDoctor`, formData);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data; // This will contain the error message from the server
      } else {
        throw new Error("Error adding data");
      }
    }
  };

  return (
    <div className="mx-auto p-6 border border-gray-300 rounded-lg bg-white">
      <div className="form-header flex justify-between items-center mb-4 border-b border-gray-700 pb-3">
        <h2 className="form-title text-2xl font-bold text-black">Add Therapist</h2>
        <button
          className="text-gray-600 hover:text-blue-500 text-2xl focus:outline-none"
          onClick={() => navigate("/therapist")}
        >
          &times;
        </button>
      </div>
      <Form
        mode="add"
        onSubmit={handleSubmit(onSubmit)}
        fields={fields.map((field) => ({
          ...field,
          options:
            field.name === "state"
              ? Object.keys(states).map((state) => ({
                  value: state,
                  label: state,
                }))
              : field.name === "city"
              ? cities.map((city) => ({ value: city, label: city }))
              : undefined, // No options for other fields
        }))}
        register={register}
        errors={errors}
        handleStateChange={handleStateChange}
        handleCityChange={handleCityChange}
      />
    </div>
  );
};

export default DoctorAdd;
