// src/components/Label.js
import React from 'react';

const Label = ({ classes, label, tooltip }) => (
  <label className={classes}>
    {label}
    {tooltip && <span className="tooltip">{tooltip}</span>}
  </label>
);

export default Label;
