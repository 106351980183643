import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

const PodcastAdd = () => {
  const { id } = useParams(); // To get the podcast ID from the URL for edit mode
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');

  // Effect to check if the ID exists and fetch the podcast data for edit mode
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      // Fetch the podcast details if ID is present
      axios
        .get(`${API_URL}/getPodcastById/${id}`)
        .then((response) => {
          const podcast = response.data.data;
          setTitle(podcast.title);
          setDescription(podcast.description || ''); // Optional description
          setLink(podcast.link);
        })
        .catch((error) => {
          console.error('Error fetching podcast:', error);
          toast.error("Error fetching podcast details", { autoClose: 2000 });
        });
    }
  }, [id]);

  // Handle form submission (both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure title and link are filled (required fields)
    if (!title || !link) {
      toast.error("Title and Link are required fields", { autoClose: 2000 });
      return;
    }

    try {
      const podcastData = {
        title,
        description,
        link,
      };

      // If in edit mode, update the existing podcast
      if (isEditMode) {
        await axios.put(`${API_URL}/updatePodcast/${id}`, podcastData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        toast.success("Podcast updated successfully", { autoClose: 2000 });
      } else {
        // If in create mode, create a new podcast
        await axios.post(`${API_URL}/createPodcast`, podcastData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        toast.success("Podcast created successfully", { autoClose: 2000 });
      }

      // Redirect to the list of podcasts after submission
      navigate('/podcast');
    } catch (error) {
      console.error('Error saving Podcast:', error);
      toast.error("Error saving Podcast", { autoClose: 2000 });
    }
  };

  return (
    <div className="container mt-5">
      <h2>{isEditMode ? 'Edit Podcast' : 'Create New Podcast'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description (Optional)</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Link</label>
          <input
            type="url"
            className="form-control"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isEditMode ? 'Update Podcast' : 'Save Podcast'}
        </button>
      </form>
    </div>
  );
};

export default PodcastAdd;
