import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../components/commonComponent/Card';
import axios from 'axios';
import CombinedCharts from '../../components/commonComponent/CombinedCharts'; 

const API_URL = process.env.REACT_APP_API_URL;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Dashboard = () => {
  const [counts, setCounts] = useState({ doctorCount: 0, userCount: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get(`${API_URL}/getUserDoctorCount`);
        setCounts({ doctorCount: response.data.doctorCount, userCount: response.data.userCount });
      } catch (error) {
        console.error("Error fetching counts", error);
      } finally {
        setLoading(false)
      }
    };

    fetchCounts();
  }, []);

  return (
    <DashboardContainer>
      <CardsContainer>
        <Card title="Total Clients" value={counts.userCount} isDoctor={false} loading={loading} />
        <Card title="Total Therapists" value={counts.doctorCount} isDoctor={true} loading={loading} />
      </CardsContainer>

      <ChartContainer>
        <CombinedCharts counts={counts} /> 
      </ChartContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
