// src/components/Input.js
import React from 'react';
import { useController } from 'react-hook-form';
import clsx from 'clsx';
import ErrorDiv from '../ErrorDiv';
import Label from '../Label';

export const Input = ({
  label,
  controlProp,
  tooltip = "",
  placeholder = "",
  onChange = false,
  type = "text",
  numbersOnly = false,
  errorAlignEnd,
  variant = "small",
  labelClass = "",
  customClasses = "",
  className = "",
  isDisabled = false,
  showValidatedIcon = true,
  ...rest
}) => {
  const { field, fieldState } = useController(controlProp);

  return (
    <div>
      {label && <Label classes={labelClass} label={label} tooltip={tooltip} />}
      <div className="relative mb-1">
        <input
          className={clsx(
            variant,
            fieldState.invalid ? "border-primary bg-light-orange-200" : "",
            className,
            customClasses
          )}
          disabled={isDisabled}
          {...rest}
          type={type}
          placeholder={placeholder}
          {...field}
          onChange={(e) => {
            if (onChange) onChange(e);
            else {
              field.onChange(e);
            }
          }}
          {...(numbersOnly && { onKeyDown: (e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          } })}
        />
        {fieldState.invalid && <ErrorDiv error={fieldState.error?.message} errorAlignEnd={errorAlignEnd} />}
      </div>
    </div>
  );
};

