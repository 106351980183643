import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';


const API_URL = process.env.REACT_APP_API_URL;

const Profile = () => {
  const { user, setUser } = useAuth();

  const [formData, setFormData] = useState({
    username: '',
    email: ''
  });

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username,
        email: user.email
      });
    }
  }, [user]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // API call to update user information
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      const response = await axios.put(
        `${API_URL}/updateAdmin/${user._id}`, // API endpoint for updating admin info with user ID
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in headers
          },
        }
      );

      // Update user in context with the new data
      setUser(response.data.user);
      setMessage('Profile updated successfully!');
      setError('');
    } catch (err) {
      setError('Failed to update profile. Please try again.');
      setMessage('');
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Profile</h2>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold mb-4">Update Profile Information</h3>
        
        {message && <div className="bg-green-100 p-2 mb-4 rounded">{message}</div>}
        {error && <div className="bg-red-100 p-2 mb-4 rounded">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Username:</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>

          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            Update Profile
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
