// src/components/header/Header.js
import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
// import '../../styles/Header.css';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  const handleProfile = () => {
    navigate('/profile')
  }

  return (
    <header className="header-container w-full  h-[60px] bg-[#F7F7F7] text-[#333333] flex items-center fixed top-0 right-0 left-auto px-5 box-border shadow-md justify-between lg:justify-end z-40 ">
    <div className="flex items-center space-x-3">
      {/* Add a responsive logo or title for smaller screens */}
      <img
        src="/images/logo.svg"
        alt="Logo"
        className="block w-8 h-8 sm:hidden" // Hidden on small screens and below
      />
     
    </div>
  
    <Dropdown>
      <Dropdown.Toggle as="div" id="dropdown-basic" className="cursor-pointer">
        {/* Optional avatar or username */}
        {/* <img className="header-avatar w-8 h-8 rounded-full" src={user.image || '/images/defaultProfile.jpg'} alt="Profile" /> */}
        {user && user.username}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleProfile}>My Profile</Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </header>
  
  );
};

export default Header;
