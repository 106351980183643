import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { patientSchema } from '../../utils/validationSchema';
import Form from '../../components/commonComponent/Form';

const API_URL = process.env.REACT_APP_API_URL;

const fields = [
  { label: 'First Name', name: 'firstName', placeholder: 'Enter first name' },
  { label: 'Last Name', name: 'lastName', placeholder: 'Enter last name' },
  { label: 'Email', name: 'email', placeholder: 'Enter email', type: 'email' },
  { label: 'State', name: 'state', placeholder: 'Enter state', type: 'dropdown' },
  { label: 'City', name: 'city', placeholder: 'Enter city', type: 'dropdown' },
  { label: 'Password', name: 'password', placeholder: 'Enter Password' },

];

const addDoctor = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/signupclient`, formData);
    return response.data;
  } catch (error) {
    console.error("Error adding doctor:", error);
    throw error; 
  }
};

const PatientAdd = () => {
  const navigate = useNavigate();
  const { register,setValue, handleSubmit, clearErrors, formState: { errors } } = useForm({
    resolver: yupResolver(patientSchema),  
  });

  const [states, setStates] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  const handleClose = () => {
    navigate('/patient'); 
  };

    // Fetch states from API
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${API_URL}/india`);
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    useEffect(() => {
      fetchStates();
    }, []);
 
  const handleStateChange = async (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setValue('state', state); 
    clearErrors("state"); 

    
    if (state) {
    try {
    const response = await axios.get(`${API_URL}/india/${state}`);
    const cities = response.data;
    console.log('Fetched cities:', cities); 
    setCities(cities || []); 
    setValue('city', ''); 
    } catch (error) {
    console.error('Error fetching cities:', error);
    setCities([]);
    setValue('city', ''); 
    }
    } else {
    setCities([]);
    setValue('city', ''); 
    }
    };
    const handleCityChange = (event) => {
      const city = event.target.value;
      setValue('city', city); 
      clearErrors("city"); 

    };

  const onSubmit = async (formData) => {
    try {
      const result = await addDoctor({ 
        ...formData, 
        city: formData.city, 
        state: selectedState 
      });
      toast.success('Data added successfully', {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate('/patient');
      }, 2000);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Error adding data';
      toast.error(errorMessage, {
        autoClose: 2000,
      });
    }
  };

  return (
<div className="  mx-auto p-6 border border-gray-300 rounded-lg bg-white">
  <div className="form-header flex justify-between items-center mb-4 border-b border-gray-700 pb-3">
    <h2 className="form-title text-2xl font-bold text-black">Add Client</h2>
    <button
      className="text-gray-600 hover:text-blue-500 text-2xl focus:outline-none"
      onClick={handleClose}
    >
      &times;
    </button>
  </div>
  
  <Form
mode="add"
onSubmit={handleSubmit(onSubmit)}
fields={fields.map(field => ({
...field,
options: field.name === 'state'
? Object.keys(states).map(state => ({ value: state, label: state }))
: field.name === 'city'
? cities.map(city => ({ value: city, label: city }))
: undefined 
}))}
register={register}
errors={errors}
handleStateChange={handleStateChange}
handleCityChange={handleCityChange}
/>
  
</div>

  );
};

export default PatientAdd;
