import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa"; // Import "Open Web" icon
import "./StoresListing.css";

const API_URL = process.env.REACT_APP_API_URL;

const StoreWebView = () => {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStores = async () => {
      const response = await fetch(`${API_URL}/getAllStores`);
      const data = await response.json();
      console.log(data, "Fetched stores");

      setStores(data?.data || []);
    };

    fetchStores();
  }, []);

  return (
    <div className="blog-listing">
      <div className="blog-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {stores.length > 0 ? (
          stores.map((store) => (
            <div
              key={store._id}
              className="relative blog-card bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
              onClick={() => window.open(store.link, "_self")} // Open link in a new tab
            >
              {/* Image with Open Web Icon */}
              <div className="relative">
                {/* <img src={store.image} alt={store.title} className="w-full h-48 object-cover" /> */}

                {/* Open Web Icon Overlay */}
                <FaExternalLinkAlt className="absolute top-2 left-2 w-10 h-10 text-gray-700 bg-white p-2 " />
              </div>

              {/* Content */}
              <div className="p-4 mt-4">
                <h2 className="text-lg font-bold">{store.title}</h2>
                <p className="text-gray-600">
                  {store.description?.substring(0, 100)}...
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-center">Loading stores...</p>
        )}
      </div>
    </div>
  );
};

export default StoreWebView;
