// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
    flex-grow: 1; /* Allows the search input to take as much space as possible */
    max-width: 40%; /* Limit the max width of the search input */
    margin-top: 10px;
  }
  
  .search-input {
    width: 100%; /* Adjust the input to fill the space given */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    color: black; /* Set text color to black */

    
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Search.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,8DAA8D;IAC5E,cAAc,EAAE,4CAA4C;IAC5D,gBAAgB;EAClB;;EAEA;IACE,WAAW,EAAE,6CAA6C;IAC1D,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,YAAY,EAAE,4BAA4B;;;EAG5C","sourcesContent":[".search-container {\n    flex-grow: 1; /* Allows the search input to take as much space as possible */\n    max-width: 40%; /* Limit the max width of the search input */\n    margin-top: 10px;\n  }\n  \n  .search-input {\n    width: 100%; /* Adjust the input to fill the space given */\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    font-size: 16px;\n    color: black; /* Set text color to black */\n\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
