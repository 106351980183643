// src/forms/LoginForm.js
import React from 'react';
import { useFormContext } from '../context/FormContext';
import { Input } from '../components/commonComponent/FormInput';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginForm = () => {
  const methods = useFormContext();
  const { login } = useAuth();
const navigate = useNavigate()
  const onSubmit = async(data) => {
    const success = await login(data.email, data.password);
    if (success) {
      toast.success('Login successful', {
        autoClose: 2000
      })
      setTimeout(() => {
        navigate('/home');
      }, 1000);
    } else {
      console.error('Login failed');
    }
  };

  return (
    <div className="w-full  p-8 space-y-6 bg-white shadow-lg rounded-lg">
      <div >
       <h2 className="text-3xl font-semibold text-center text-gray-700">Login</h2>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Input
        label="Email"
        controlProp={{ name: "email", control: methods.control }}
        placeholder="Enter your email"
        type="email"
        className="w-full px-4 py-3 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 bg-gray-50 mb-3"
        labelClass="text-gray-700 font-medium mb-2" 
      />
      <Input
        label="Password"
        controlProp={{ name: "password", control: methods.control }}
        placeholder="Enter your password"
        type="password"
        className="w-full px-4 py-3 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 bg-gray-50 "
        labelClass="text-gray-700 font-medium mb-2" 
      />
      <button type="submit"  className="w-full mt-3 px-4 py-3 bg-gray-800 text-white rounded hover:bg-gray-700 transition">Login</button>
      <span className='text-center text-gray-600 inline-block w-full mt-3'>Don't have an account?<Link to="/register" className='text-blue-500 hover:underline'> Create Account</Link></span>
    </form>
    </div>
    </div>
  );
};

export default LoginForm;
