import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'; 
import Form from '../../components/commonComponent/Form'; 
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

const fields = [
  { label: 'First Name', name: 'firstName', placeholder: 'Enter first name', disabled: true },
  { label: 'Last Name', name: 'lastName', placeholder: 'Enter last name', disabled: true },
  { label: 'Email', name: 'email', placeholder: 'Enter email', type: 'email', disabled: true },
  { label: 'State', name: 'state', placeholder: 'Enter state' },
  { label: 'City', name: 'city', placeholder: 'Enter city' },
  { label: 'Profile Image', name: 'profileImage', placeholder: 'Update profile image' },
  { label: 'Age Group', name: 'ageGroup', placeholder: 'Enter age group' },
  { label: 'Relationship Status', name: 'relationshipStatus', placeholder: 'Enter relationship status' },
  { label: 'Sport', name: 'sport', placeholder: 'Enter sport you play' },
  { label: 'Therapy Expectations', name: 'therapyExpectations', placeholder: 'Enter therapy expectations' },
  { label: 'Therapy Reason', name: 'therapyReasons', placeholder: 'Enter your therapy reason' }
];

const updatePatient = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/updateUserInfo/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error updating patient:", error);
    throw error; 
  }
};

const fetchPatientById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/getuserById/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching patient by ID:", error);
    throw error;
  }
};

const PatientUpdate = () => {
  const { id } = useParams(); 
  const [initialData, setInitialData] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm(); 
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const loadPatientData = async () => {
        try {
          const data = await fetchPatientById(id);
          setInitialData(data);
          Object.keys(data).forEach((key) => {
            setValue(key, data[key]);
          });
        } catch (error) {
          console.error("Error loading patient data", error);
        }
      };
      loadPatientData();
    }
  }, [id, setValue]);

  const onSubmit = async (formData) => {
    try {
      await updatePatient(id, formData);
      toast.success('Data updated successfully', {
        autoClose: 2000
      });
      setTimeout(() => {
        navigate('/patient');
      }, 2000);
    } catch (error) {
      toast.error('Error updating data', {
        autoClose: 2000
      });
    }
  };

  const handleClose = () => {
    navigate('/patient'); 
  };

  return (
    <div className=" mx-auto p-6 border border-gray-300 rounded-lg bg-white">
    <div className="form-header flex justify-between items-center mb-4 border-b border-gray-700 pb-3">
      <h2 className="form-title text-2xl font-bold text-black">Update Client</h2>
      <button 
        className="text-gray-400 hover:text-gray-600  transition duration-150 text-2xl font-bold focus:outline-none" 
        onClick={handleClose}
      >
        &times;
      </button>
    </div>
  
    {initialData ? (
      <Form
        mode="update"
        onSubmit={handleSubmit(onSubmit)} 
        fields={fields}
        register={register} 
        errors={errors} 
      />
    ) : (
      <p className="text-center text-gray-500">Loading...</p>
    )}
  </div>
  
  );
};

export default PatientUpdate;
