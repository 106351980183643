import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import DeleteConfirmationModal from '../../components/commonComponent/DeleteConfirmationModal';
import useModal from '../../hooks/useModal';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

const Questionnaire = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  const {
    showDeleteModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal
  } = useModal();

  useEffect(() => {
    // Fetch questions from the backend
    axios.get(`${API_URL}/getAllQuestions`)
      .then(response => {
        setQuestions(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the questions!', error);
      });
  }, []);

  const handleAddQuestionClick = () => {
    navigate('/questionnare-add');
  };

  const handleEditQuestion = (id) => {
    // Implement the logic for editing a question
    navigate(`/questionnare-update/edit/${id}`);
  };

  const handleDeleteQuestion = () => {
    if (!selectedItem) return;

    axios.delete(`${API_URL}/deleteQuestion/${selectedItem._id}`)
      .then(response => {
        // Refresh the question list after deletion
        setQuestions(questions.filter(question => question._id !== selectedItem._id));
        handleCloseDeleteModal();
        toast.success("Deleted Successfully", { autoClose: 2000 });
      })
      .catch(error => {
        toast.error("Error deleting data", { autoClose: 1000 });
        console.error('There was an error deleting the question!', error);
      });
  };

  const toggleStatus = async (question) => {
    const updatedStatus = !question.isActive;

    try {
      const response = await axios.put(`${API_URL}/updateQuestionStatus/${question._id}`, {
        isActive: updatedStatus
      });

      // Update the questions state after the status is toggled
      setQuestions(questions.map(q =>
        q._id === question._id ? { ...q, isActive: updatedStatus } : q
      ));

      toast.success(`Question is now ${updatedStatus ? 'Visible' : 'Hidden'}`, {
        autoClose: 2000
      });
    } catch (error) {
      toast.error("Failed to update status", { autoClose: 1000 });
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="mx-auto p-6 border border-gray-300 rounded-lg bg-white">
      <div className="form-header flex justify-between items-center mb-4">
        <h2 className="form-title text-2xl font-bold text-black">Questionnaire</h2>
        <button className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition" onClick={handleAddQuestionClick}>
          Add Question
        </button>
      </div>
      {questions.length > 0 ? (
        questions.map((question, index) => (
          <div key={index} className="form-group mb-4 border border-gray-300 p-2.5 rounded-md">
            <div className='flex'>
              <label className="block text-gray-700 font-semibold">{question.label}</label>
              {/* Show/Hide button */}
              <button
                className='bg-[#1c2434] text-white rounded-[16px] px-3 py-1 capitalize ms-auto'
                onClick={() => toggleStatus(question)}
              >
                {question.isActive ? 'Hide' : 'Show'}
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div>
                {question.optionsType === 'radio' || question.optionsType === 'checkbox' ? (
                  question.options.map((option, idx) => (
                    <div key={idx} className="flex items-center mb-2">
                      <input
                        type={question.optionsType}
                        id={`${question.label}-${idx}`}
                        name={question.label}
                        value={option.value}
                        className="mr-2"
                      />
                      <label htmlFor={`${question.label}-${idx}`} className="text-gray-600">{option.label}</label>
                    </div>
                  ))
                ) : question.optionsType === 'dropdown' ? (
                  <select className="p-2 border border-gray-300 rounded w-full text-gray-600">
                    {question.options.map((option, idx) => (
                      <option key={idx} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                ) : question.optionsType === 'text' ? (
                  <input type="text" className="p-2 border border-gray-300 rounded w-full" placeholder="Your answer" />
                ) : null}
              </div>
              <div className="flex justify-end items-center">
                <FaEdit
                  className="text-blue-600 hover:text-blue-700 cursor-pointer mr-2"
                  onClick={() => handleEditQuestion(question._id)}
                />
                <FaTrashAlt
                  className="text-red-600 hover:text-red-700 cursor-pointer"
                  onClick={() => handleOpenDeleteModal(question)}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No questions available.</p>
      )}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteQuestion}
      />
    </div>
  );
};

export default Questionnaire;
