import React from 'react';
import LoginForm from '../../forms/LoginForm';

const Login = () => (
<div className='w-full  p-6 space-y-6 bg-white shadow-md rounded-lg h-screen'>


  <div className="container  login-container">
    <div className="flex items-center justify-center  ">
      <div className='w-full md:w-1/2 p-4'>
        <div className="p-6 shadow-md rounded-lg bg-white">
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
  </div>
);

export default Login;
