import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const SportPsychologyTrainingAdd = () => {
  const { id } = useParams(); // To get the webinar ID from the URL for edit mode
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  // Effect to check if the ID exists and fetch the webinar data for edit mode
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      // Fetch the webinar details if ID is present
      axios
        .get(`${API_URL}/getSportPsychologyTrainingById/${id}`)
        .then((response) => {
          const sport = response.data.data;
          setTitle(sport.title);
          setDescription(sport.description || ""); // Optional description
          setLink(sport.link);
        })
        .catch((error) => {
          console.error("Error fetching sport Psychology Training:", error);
          toast.error("Error fetching sport Psychology Training details", {
            autoClose: 2000,
          });
        });
    }
  }, [id]);

  // Handle form submission (both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure title and link are filled (required fields)
    if (!title || !link) {
      toast.error("Title and Link are required fields", { autoClose: 2000 });
      return;
    }

    try {
      const sportData = {
        title,
        description,
        link,
      };

      // If in edit mode, update the existing webinar
      if (isEditMode) {
        await axios.put(
          `${API_URL}/updateSportPsychologyTraining/${id}`,
          sportData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Sport Psychology Training updated successfully", {
          autoClose: 2000,
        });
      } else {
        // If in create mode, create a new webinar
        console.log("api", API_URL);
        await axios.post(
          `${API_URL}/createSportPsychologyTraining`,
          sportData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Sport Psychology Training created successfully", {
          autoClose: 2000,
        });
      }

      // Redirect to the list of webinars after submission
      navigate("/sportpsychologytrainings");
    } catch (error) {
      console.error("Error saving Sport Psychology Training:", error);
      toast.error("Error saving Sport Psychology Training", {
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>
        {isEditMode
          ? "Edit Sport Psychology Training"
          : "Create New Sport Psychology Training"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description (Optional)</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Link</label>
          <input
            type="url"
            className="form-control"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isEditMode
            ? "Update Sport Psychology Training"
            : "Save Sport Psychology Training"}
        </button>
      </form>
    </div>
  );
};

export default SportPsychologyTrainingAdd;
