import React from "react";
import PropTypes from "prop-types";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaWpforms,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import Loader from "./Loader";
const Table = ({
  columns,
  data,
  showIndex = false,
  currentPage = 1,
  itemsPerPage = 10,
  onDelete,
  onActivate,
  onUpdate,
  onView,
  loading = false,
  showEdit = true,
  showActivate = false,
}) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
        <thead className="bg-gray-800 text-white uppercase text-sm font-semibold">
          <tr>
            {showIndex && (
              <th className="px-6 p-[14px] border-b text-left">S.No</th>
            )}
            {columns.map((column, index) => (
              <th key={index} className="px-6 p-[14px] border-b text-left">
                {column.header}
              </th>
            ))}
            <th className="px-6 p-[14px] border-b text-center">Actions</th>
          </tr>
        </thead>

        <tbody className="text-gray-600 text-sm">
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="hover:bg-gray-50 transition duration-200"
            >
              {showIndex && (
                <td className="px-6 p-[14px] border-b text-left">
                  {(currentPage - 1) * itemsPerPage + rowIndex + 1}
                </td>
              )}
              {columns.map((column, colIndex) => (
                <td key={colIndex} className="px-6 p-[14px] border-b text-left">
                  {row[column.accessor]}
                </td>
              ))}
              <td className="px-6 p-[14px] border-b ">
                <div className="flex items-center gap-x-[5px]">
                  <button
                    onClick={() => onView(row)}
                    title="View"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded transition duration-150"
                  >
                    <FaEye />
                  </button>
                  {showEdit && (
                    <button
                      onClick={() => onUpdate(row)}
                      title="Update"
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded transition duration-150"
                    >
                      <FaEdit />
                    </button>
                  )}
                  <button
                    onClick={() => onDelete(row)}
                    title="Delete"
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded transition duration-150"
                  >
                    <FaTrash />
                  </button>

                  {showActivate && (
                    <button
                      onClick={() => onActivate(row)}
                      title={row.status ? "false" : "true"}
                      className={`${
                        row.status
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-gray-500 hover:bg-gray-700"
                      } text-white font-bold py-1 px-2 rounded transition duration-150`}
                    >
                      {row.status ? <FaToggleOn /> : <FaToggleOff />}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showIndex: PropTypes.bool,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  onDelete: PropTypes.func,
  onActivate: PropTypes.func,
  onUpdate: PropTypes.func,
  onView: PropTypes.func,
  onForm: PropTypes.func,
  showEdit: PropTypes.bool,
  showForm: PropTypes.bool,
};

export default Table;
