import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify';

const firebaseConfig = {
  apiKey: "AIzaSyB4A-V0WIdmzRehRBvHXW_e6hYXphDsGlw",
  authDomain: "therapist-app-aa7c1.firebaseapp.com",
  projectId: "therapist-app-aa7c1",
  storageBucket: "therapist-app-aa7c1.appspot.com",
  messagingSenderId: "673979209203",
  appId: "1:673979209203:android:0256afae31a4f6eea6ae86",
};

// Initialize Firebase
initializeApp(firebaseConfig);
const storage = getStorage();


const API_URL = process.env.REACT_APP_API_URL;

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
    [{ align: [] }],
  ],
};

const BlogAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const quillRef = useRef(null);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      axios
        .get(`${API_URL}/getBlogById/${id}`)
        .then((response) => {
          const blog = response.data.data;
          setTitle(blog.title);
          setDescription(blog.description);
          setContent(blog.content);
          setMetaTitle(blog.meta.title);
          setMetaDescription(blog.meta.description);
          setMetaKeywords(blog.meta.keywords);
          if (blog.image) setImagePreview(blog.image);
          if (blog.video) setVideoPreview(blog.video);
        })
        .catch((error) => {
          console.error('Error fetching blog:', error);
      toast.error("Error fetching blog details", { autoClose: 2000 });

        });
    }
  }, [id]);

  const uploadToFirebase = (file, type) => {
    return new Promise((resolve, reject) => {
      const fileName = file.name || `${Date.now()}_file`;
      const storageRef = ref(storage, `${type}/${Date.now()}_${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`${type} upload is ${progress}% done`);
        },
        (error) => {
          console.error(`Error uploading ${type}:`, error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(`${type} uploaded successfully. URL:`, downloadURL);
            resolve(downloadURL);
          } catch (error) {
            console.error(`Error fetching ${type} URL:`, error);
            reject(error);
          }
        }
      );
    });
  };
  
  const handleFileUpload = async (file, type) => {
    try {
      // Generate a local preview for the selected file
      const previewUrl = URL.createObjectURL(file);
      if (type === 'image') {
        setImagePreview(previewUrl); // Update image preview
        const uploadedImageUrl = await uploadToFirebase(file, 'images'); // Upload to Firebase
        setImageUrl(uploadedImageUrl); // Update image URL after upload
      } else if (type === 'video') {

        setVideoPreview(previewUrl); // Update video preview
        const uploadedVideoUrl = await uploadToFirebase(file, 'videos'); // Upload to Firebase
        setVideoUrl(uploadedVideoUrl); // Update video URL after upload
        setVideoPreview(null); // Optionally clear the Blob preview

      }
    } catch (error) {
      console.error(`Error handling ${type} file upload:`, error);
      toast.error(`Error uploading ${type}`, { autoClose: 2000 });
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (imageFile) {
        const uploadedImageUrl = await uploadToFirebase(imageFile, 'images');
        setImageUrl(uploadedImageUrl);
      }
      if (videoFile) {
        const uploadedVideoUrl = await uploadToFirebase(videoFile, 'videos');
        setVideoUrl(uploadedVideoUrl);
      }

      const blogData = {
        title,
        description,
        content,
        metaTitle,
        metaDescription,
        metaKeywords,
        image: imageUrl || imagePreview,
        video: videoUrl, // Use the uploaded Firebase URL
      };

      if (isEditMode) {
        await axios.put(`${API_URL}/updateBlog/${id}`, blogData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        toast.success("Blog updated successfully", { autoClose: 2000 });
      } else {
        await axios.post(`${API_URL}/createBlog`, blogData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        toast.success("Blog created successfully", { autoClose: 2000 });
      }
      navigate('/blogs');
    } catch (error) {
      console.error('Error saving blog:', error);
      toast.error("Error saving blog", { autoClose: 2000 });

    }
  };

  return (
    <div className="container mt-5">
      <h2>{isEditMode ? 'Edit Blog' : 'Create New Blog'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Content</label>
          <ReactQuill
            ref={quillRef}
            value={content}
            onChange={setContent}
            modules={modules}
          />
        </div>

        <div className="form-group">
  <label>Upload Image</label>
  <input
    type="file"
    accept="image/*"
    onChange={(e) => {
      const file = e.target.files[0];
      if (file) handleFileUpload(file, 'image');
    }}
  />
  {imagePreview && (
    <div className="mt-2">
      <img
        src={imagePreview}
        alt="Preview"
        style={{ maxWidth: '150px', height: '100px', objectFit: 'cover' }}
      />
    </div>
  )}
</div>

<div className="form-group">
  <label>Upload Video</label>
  <input
    type="file"
    accept="video/*"
    onChange={(e) => {
      const file = e.target.files[0];
      if (file) handleFileUpload(file, 'video');
    }}
  />
  {videoPreview && (
    <div className="mt-2">
      <video
        controls
        style={{ maxWidth: '150px', height: '100px', objectFit: 'cover' }}
      >
        <source src={videoPreview} type="video/mp4" />
      </video>
    </div>
  )}
</div>


        <h3>Metadata</h3>
        <div className="form-group">
          <label>Meta Title</label>
          <input
            type="text"
            className="form-control"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Meta Description</label>
          <textarea
            className="form-control"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Meta Keywords</label>
          <input
            type="text"
            className="form-control"
            value={metaKeywords}
            onChange={(e) => setMetaKeywords(e.target.value)}
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isEditMode ? 'Update Blog' : 'Save Blog'}
        </button>
      </form>
    </div>
  );
};

export default BlogAdd;
