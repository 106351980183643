import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchData,
  deleteData,
  handlePageChange,
  getCurrentData,
} from "../../utils/utils";
import useModal from "../../hooks/useModal";
import Pagination from "../../components/commonComponent/Pagination";
import DeleteConfirmationModal from "../../components/commonComponent/DeleteConfirmationModal";
import Table from "../../components/commonComponent/Table";
import ViewModal from "../../components/commonComponent/ViewModal";
import Search from "../../components/commonComponent/Search"; 
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const columns = [
  { header: "Title", accessor: "title" },
  { header: "Description", accessor: "description" },
  { header: "Created At", accessor: "createdAt" },
];

const ITEMS_PER_PAGE = 10;

const Webinar   = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const {
    showDeleteModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenViewModal,
    handleCloseViewModal,
  } = useModal();

  useEffect(() => {
    fetchData(`${API_URL}/getAllWebinars`, (result) => {
      setData(result);
      setFilteredData(result);
      setLoading(false);
    }, setError);
  }, []);

  // Handle search
  const handleSearch = (searchTerm) => {
    const filtered = data.filter((blog) => {
      const title = blog.title || '';
      const description = blog.description || '';
      // const metaTitle = blog.meta?.title || '';
      return (
        title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        description.toLowerCase().includes(searchTerm.toLowerCase())
        // metaTitle.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleDeleteConfirm = () => {
    deleteData(`${API_URL}/deleteWebinar`, selectedItem._id, setError)
      .then(() => {
        setData((prevData) =>
          prevData.filter((item) => item._id !== selectedItem._id)
        );
        setFilteredData((prevFilteredData) =>
          prevFilteredData.filter((item) => item._id !== selectedItem._id)
        );

        toast.success("Deleted successfully", { autoClose: 2000 });
      })
      .catch((error) => {
        setError(error);
        toast.error("Error deleting data", { autoClose: 2000 });
      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(filteredData, currentPage, ITEMS_PER_PAGE);

  const fieldsToShow = {
    Title: "title",
    Description: "description",
    "Link":"link",
    "Created At": "createdAt",
  };

  const titles = [...new Set(data.map((blog) => blog.title))];
  const metaTitles = [...new Set(data.map((blog) => blog.meta?.title))];
  
  const filterOptions = {
    Title: titles,
    MetaTitle: metaTitles,
  };
  console.log(filterOptions,'memewmwem')

  return (
    <div className="blog-page">
      <div className="flex justify-between items-center text-[#1B1F2E] ps-0 pb-2 pt-3 mb-2">
        <h2 className="text-[28px] font-semibold">Webinars List</h2>
        <Link
          to="/webinars-add"
          className="bg-gray-800 text-white font-normal py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200"
        >
          Add Webinars
        </Link>
      </div>
      <div className="search-filter-container shadow-sm flex justify-between items-center gap-5 mb-3 flex-wrap bg-white rounded-lg px-3 py-3">
        <Search searchFields={["Title", "Meta Title"]} onSearch={handleSearch} />
      </div>

      <div className="table-container">
        <Table
          columns={columns}
          data={currentData}
          loading={loading}
          showIndex={true}
          currentPage={currentPage}
          itemsPerPage={ITEMS_PER_PAGE}
          onDelete={handleOpenDeleteModal}
          onUpdate={(item) => navigate(`/webinars/edit/${item._id}`)}
          onView={handleOpenViewModal}
          showEdit={true}
          showForm={false}
        />
        {!loading && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange(setCurrentPage)}
          />
        )}
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      <ViewModal
        show={showViewModal}
        onHide={handleCloseViewModal}
        data={selectedItem}
        fields={fieldsToShow}
      />
    </div>
  );
};

export default Webinar;
