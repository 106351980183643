import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Form.css';

const Form = ({
  fields,
  onSubmit,
  mode,
  register,
  errors,
  handleStateChange,
  handleCityChange,
  initialData
}) => {
  return (
    <form className="form-container" onSubmit={onSubmit}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[20px]">
        {fields.map((field) => (
          <div className="form-group" key={field.name}>
            <label htmlFor={field.name} className='text-gray-800 mb-2'>{field.label}</label>

            {field.type === 'dropdown' ? (
              <select
                id={field.name}
                className={`form-control ${errors[field.name] ? 'is-invalid' : ''}`}
                {...register(field.name)}
                onChange={field.name === 'state' ? handleStateChange : handleCityChange}
              >
                <option value="">Select {field.label}</option>

                {field.name === 'providerType' ? (
                  <>
                    <option value="Sport Psychologist">Sport Psychologist</option>
                    <option value="Sport Psychology Consultant">Sport Psychology Consultant</option>
                    <option value="Mental Training Coach">Mental Training Coach</option>
                    <option value="Motivational Speaker">Motivational Speaker</option>
                  </>
                ) : (
                  field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))
                )}
              </select>
            ) : (
              <>
                {field.name === 'profileImage' ? (
                  initialData?.profileImage ? (
                    <img src={initialData.profileImage} alt="Profile" className="w-32 h-32 object-cover mt-2" />
                  ) : (
                    <p className="text-gray-500 mt-2">N/A</p>
                  )
                ) : field.name === 'license' ? (
                  initialData?.license ? (
                    <img src={initialData.license} alt="License" className="w-32 h-32 object-cover mt-2" />
                  ) : (
                    <p className="text-gray-500 mt-2">N/A</p>
                  )
                ) : field.name === 'recordNow' ? (
                  initialData?.recordNow ? (
                    <video controls className="w-32 h-32 object-cover mt-2">
                      <source src={initialData.recordNow} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <p className="text-gray-500 mt-2">N/A</p>
                  )
                ) : (
                  <input
                    type={field.type || 'text'}
                    className={`form-control ${errors[field.name] ? 'is-invalid' : ''}`}
                    id={field.name}
                    placeholder={field.placeholder}
                    {...register(field.name)}
                    disabled={field.disabled}
                  />
                )}
              </>
            )}

            {errors[field.name] && (
              <div className="invalid-feedback">{errors[field.name].message}</div>
            )}
          </div>
        ))}
      </div>
      <button type="submit" className="bg-gray-800 px-4 text-white font-semibold py-2 mb-4 rounded-md shadow hover:bg-gray-700 transition duration-200">
        {mode === 'add' ? 'Add' : 'Update'} Data
      </button>
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      options: PropTypes.array,
      disabled: PropTypes.bool
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  handleCityChange: PropTypes.func.isRequired,
  initialData: PropTypes.object
};

export default Form;
