import React, { useEffect, useState } from "react";
import axios from "axios";
import Form from "../../components/commonComponent/Form"; 
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { therapistSchema } from "../../utils/validationSchema";

const API_URL = process.env.REACT_APP_API_URL;

const fields = [
  {
    label: "First Name",
    name: "firstName",
    placeholder: "Enter first name",
    required: true,
  },
  { label: "Last Name", name: "lastName", placeholder: "Enter last name" },
  {
    label: "Email",
    name: "email",
    placeholder: "Enter email",
    type: "email",
    required: true,
  },
  {
    label: "Provider Type",
    name: "providerType",
    placeholder: "Select providerType",
    type: "dropdown",
    required: true,
  },
  {
    label: "Degree",
    name: "degree",
    placeholder: "Enter Degree",
    type: "text",
    required: true,
  },
  {
    label: "Year Of Exp ",
    name: "yearOfExp",
    placeholder: "Enter Year Of Exp ",
    type: "text",
    required: true,
  },
  {
    label: "Fee",
    name: "fee",
    placeholder: "Enter Fees",
    type: "text",
    required: true,
  },
  {
    label: "Language",
    name: "language",
    placeholder: "Enter language",
    type: "text",
    required: true,
  },
  {
    label: "State",
    name: "state",
    placeholder: "Select state",
    type: "dropdown",
    required: true,
  },
  {
    label: "City",
    name: "city",
    placeholder: "Select city",
    type: "dropdown",
    required: true,
  },
 
  {
    label: "Password",
    name: "password",
    placeholder: "Enter Password",
    type: "password",
    required: true,
    helperText: "Must include at least 8 characters, one uppercase letter, one number, and one special character.",
  },
];

const DoctorAdd = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(therapistSchema),
  });

  const [states, setStates] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  // Fetch states from API
  const fetchStates = async () => {
    try {
      const response = await axios.get(`${API_URL}/india`);
      setStates(response.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

 

  const handleStateChange = async (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setValue("state", state); 
    clearErrors("state"); 


    if (state) {
      try {
        const response = await axios.get(`${API_URL}/india/${state}`);
        const cities = response.data;
        console.log("Fetched cities:", cities); 
        setCities(cities || []); 
        setValue("city", ""); 
      } catch (error) {
        console.error("Error fetching cities:", error);
        setCities([]);
        setValue("city", ""); 
      }
    } else {
      setCities([]);
      setValue("city", ""); 
    }
  };
  const handleCityChange = (event) => {
    const city = event.target.value;
    setValue("city", city); 
    clearErrors("city"); 

  };

  const onSubmit = async (formData) => {
    try {
      const result = await addDoctor({
        ...formData,
        city: formData.city, 
        state: selectedState, 
      });
      toast.success("Data added successfully", {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/therapist");
      }, 2000);
    } catch (error) {
      const errorMessage = error.error || 'Error adding data';
      toast.error(errorMessage, {
        autoClose: 2000,
      });
    }
  };

  const addDoctor = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/signupDoctor`, formData);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data; 
      } else {
        throw new Error("Error adding data");
      }
    }
  };

  return (
    <div className="mx-auto p-6 border border-gray-300 rounded-lg bg-white">
      <div className="form-header flex justify-between items-center mb-4 border-b border-gray-700 pb-3">
        <h2 className="form-title text-2xl font-bold text-black">Add Therapist</h2>
        <button
          className="text-gray-600 hover:text-blue-500 text-2xl focus:outline-none"
          onClick={() => navigate("/therapist")}
        >
          &times;
        </button>
      </div>
      <Form
        mode="add"
        onSubmit={handleSubmit(onSubmit)}
        fields={fields.map((field) => ({
          ...field,
          options:
            field.name === "state"
              ? Object.keys(states).map((state) => ({
                  value: state,
                  label: state,
                }))
              : field.name === "city"
              ? cities.map((city) => ({ value: city, label: city }))
              : undefined, 
        }))}
        register={register}
        errors={errors}
        handleStateChange={handleStateChange}
        handleCityChange={handleCityChange}
      />
    </div>
  );
};

export default DoctorAdd;
