import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const datePickerRef = useRef(); // Create a ref for the DatePicker

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onDateChange(start, end);
  };

  // Format the date range for the placeholder
  const dateRangePlaceholder = startDate && endDate
    ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
    : "Select a date range";

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true); // Open the DatePicker
    }
  };

  return (
    <div className="relative w-64"> {/* Set a width for the container */}
      <DatePicker 
        ref={datePickerRef} 
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        isClearable
        placeholderText={dateRangePlaceholder}
        dateFormat="dd-MM-yyyy"
        className="border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500 py-2 pl-10 pr-3 w-full text-gray-700" // Add padding for the icon
      />
      <FontAwesomeIcon 
        icon={faCalendar} 
        className="absolute top-3 left-3 text-gray-600 cursor-pointer hover:text-blue-500 transition duration-200" 
        onClick={handleIconClick} 
        size="lg"
      />
    </div>
  );
};

export default DateRangePicker;
