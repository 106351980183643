import React from 'react';
import { FaUserMd, FaUser } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im'; // Spinner icon for loading state

const Card = ({ title, value, isDoctor, loading }) => (
  <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-6 flex items-center space-x-6 hover:shadow-lg transition-shadow duration-300 ease-in-out">
    <div className="text-5xl text-gray-800 bg-gray-100 p-4 rounded-full">
      {isDoctor ? <FaUserMd /> : <FaUser />}
    </div>
    <div>
      <h4 className="text-lg font-semibold text-gray-700">{title}</h4>
      {loading ? ( // Display loader if loading is true
        <div className="text-3xl font-bold text-gray-800 mt-2 flex items-center">
          <ImSpinner2 className="animate-spin mr-2" /> {/* Spinning loader icon */}
          Loading...
        </div>
      ) : (
        <p className="text-3xl font-bold text-gray-800 mt-2">{value}</p>
      )}
    </div>
  </div>
);

export default Card;
