import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Form from '../../components/commonComponent/Form'; 
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const API_URL = process.env.REACT_APP_API_URL;

const fields = [
  { label: 'First Name', name: 'firstName', placeholder: 'Enter first name', disabled: true  },
  { label: 'Last Name', name: 'lastName', placeholder: 'Enter last name', disabled: true },
  { label: 'Email', name: 'email', placeholder: 'Enter email', type: 'email', disabled: true },
  { label: 'State', name: 'state', placeholder: 'Enter state' },
  { label: 'City', name: 'city', placeholder: 'Enter city' },
  { label: 'Bank Name', name: 'bankAccName', placeholder: 'Enter bank name', disabled: true },
  { label: 'Bank Account Number', name: 'bankAccNo', placeholder: 'Enter bank account number',disabled: true },
  { label: 'Therapist Fee', name: 'fee', placeholder: 'Enter Therapist fee', disabled: true },
  // { label: 'Job Title', name: 'jobTitle', placeholder: 'Enter Job Title' },
  { label: 'License Number', name: 'registrationNumber', placeholder: 'Enter license Number', disabled: true },
  { label: 'Routing Number', name: 'routingNumber', placeholder: 'Enter Routing Number' },
  { label: 'Therapist Specialities', name: 'specialities', placeholder: 'Enter Therapist Specialities' },
  // { label: 'Session Time', name: 'sessionTime', placeholder: 'Enter Session Timing' },
  // { label: 'Lisence Type', name: 'lisenceType', placeholder: 'Enter Lisence Type' },
  { label: 'License:', name: 'license', placeholder: 'Choose Image' }, // Update this line
  { label: 'Therapist Bio', name: 'bio', placeholder: 'Enter Therapist Bio' },
  { label: 'Profile Image: ', name: 'profileImage', placeholder: 'Choose Profile Image', type: 'file' },
  { label: 'Therapist Video', name: 'recordNow', placeholder: 'Enter Video ', type: 'file' } 
];

const updateDoctor = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/updateDrInfo/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error updating doctor:", error);
    throw error; // Rethrow error to handle it in the component
  }
};

const fetchDoctorById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/getDetailOfDoctorById/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching doctor by ID:", error);
    throw error; // Rethrow error to handle it in the component
  }
};

const TherapistUpdate = () => {
  const { id } = useParams(); // Retrieve the ID from URL parameters
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm(); // Use React Hook Form

    // Get the current page from the query parameters
    const queryParams = new URLSearchParams(location.search);
    const currentPage = queryParams.get('page') || 1; // Default to page 1 if not found

  useEffect(() => {
    if (id) { // Ensure id is available before fetching data
      const loadDoctorData = async () => {
        try {
          const data = await fetchDoctorById(id);
          setInitialData(data);
           // Set the form data for each field
           Object.keys(data).forEach((key) => {
            setValue(key, data[key]);
          });
        } catch (error) {
          // Handle error if needed
        }
      };
      loadDoctorData();
    }
  }, [id, setValue]);

  const handleClose = () => {
    navigate('/therapist');
  }

  // const onSubmit = async (formData) => {
  //   try {
  //     const result = await updateDoctor(id, formData);
  //     toast.success('Data updated successfully', {
  //       autoClose: 2000
  //     });
  //     setTimeout(() => {
  //       // Navigate back to the therapist page with the current page
  //       navigate(`/therapist?page=${currentPage}`);
  //     }, 2000);
  //   } catch (error) {
  //     toast.error('Error updating data', {
  //       autoClose: 2000
  //     });
  //   }
  // };

  const onSubmit = async (formData) => {
    try {
      const result = await updateDoctor(id, formData);
      toast.success('Data updated successfully', {
        autoClose: 2000
      });
      setTimeout(() => {
        // Pass the current page as a query parameter
        navigate(`/therapist?page=${currentPage}`);
      }, 2000);
    } catch (error) {
      toast.error('Error updating data', {
        autoClose: 2000
      });
    }
  };
  

  return (
    <div className="bg-white rounded-lg p-6 mx-auto space-y-6">
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Update Therapist</h2>
        <button 
          className="text-gray-400 hover:text-gray-600 transition duration-150 text-2xl font-bold focus:outline-none" 
          onClick={handleClose}
        >
          &times;
        </button>
      </div>
  
      {initialData ? (
        <Form
          mode="update"
          onSubmit={handleSubmit(onSubmit)}
          fields={fields}
          register={register}
          errors={errors}
          initialData={initialData} // Pass initialData for preview
        />
      ) : (
        <p className="text-center text-gray-500">Loading...</p>
      )}
    </div>
  );
};

export default TherapistUpdate;
