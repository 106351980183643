import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PublicRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    // Render a loading spinner or some kind of loading indicator
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Navigate to="/home" /> : children;
};

export default PublicRoute;
