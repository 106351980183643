import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const InTheNewsAdd = () => {
  const { id } = useParams(); // To get the inthenews ID from the URL for edit mode
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  // Effect to check if the ID exists and fetch the inthenews data for edit mode
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      // Fetch the inthenews details if ID is present
      axios
        .get(`${API_URL}/getNewsById/${id}`)
        .then((response) => {
          const inthenews = response.data.data;
          setTitle(inthenews.title);
          setDescription(inthenews.description || ""); // Optional description
          setLink(inthenews.link);
        })
        .catch((error) => {
          console.error("Error fetching inthenews:", error);
          toast.error("Error fetching inthenews details", { autoClose: 2000 });
        });
    }
  }, [id]);

  // Handle form submission (both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure title and link are filled (required fields)
    if (!title || !link) {
      toast.error("Title and Link are required fields", { autoClose: 2000 });
      return;
    }

    try {
      const inthenewsData = {
        title,
        description,
        link,
      };

      // If in edit mode, update the existing inthenews
      if (isEditMode) {
        await axios.put(`${API_URL}/updateNews/${id}`, inthenewsData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("InTheNews updated successfully", { autoClose: 2000 });
      } else {
        // If in create mode, create a new inthenews
        await axios.post(`${API_URL}/createNews`, inthenewsData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("InTheNews created successfully", { autoClose: 2000 });
      }

      // Redirect to the list of inthenewss after submission
      navigate("/inthenews");
    } catch (error) {
      console.error("Error saving inthenews:", error);
      toast.error("Error saving inthenews", { autoClose: 2000 });
    }
  };

  return (
    <div className="container mt-5">
      <h2>{isEditMode ? "Edit InTheNews" : "Create New InTheNews"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description (Optional)</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Link</label>
          <input
            type="url"
            className="form-control"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isEditMode ? "Update InTheNews" : "Save InTheNews"}
        </button>
      </form>
    </div>
  );
};

export default InTheNewsAdd;
