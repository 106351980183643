import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchData, deleteData, handlePageChange, getCurrentData, getData } from "../../utils/utils";
import useModal from "../../hooks/useModal";
import Pagination from "../../components/commonComponent/Pagination";
import DeleteConfirmationModal from "../../components/commonComponent/DeleteConfirmationModal";
import Table from "../../components/commonComponent/Table";
import ViewModal from "../../components/commonComponent/ViewModal";
import { toast } from "react-toastify";
import DateRangePicker from "../../components/commonComponent/DateRangePicker";
import { isWithinInterval } from "date-fns";


const API_URL = process.env.REACT_APP_API_URL


const columns = [
  { header: "Message", accessor: "message" },
  { header: "Therapist Email", accessor: "therapistEmail" },
  { header: "Patient Email", accessor: "patientEmail" },
  { header: "Status", accessor: "status" },
];

const ITEMS_PER_PAGE = 10;

const Notification = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true)

  const {
    showDeleteModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenViewModal,
    handleCloseViewModal,
  } = useModal();
  const navigate = useNavigate();

  const processData = (notifications) => {
    return notifications.map(notification => ({
      ...notification,
      therapistEmail: notification.receiverId?.email || 'N/A',
      patientEmail: notification.senderId?.email || 'N/A',
    }));
  };

  useEffect(() => {
    getData(`${API_URL}/getNotificationList`, setData, setError, processData, setFilteredData, setLoading);
  }, []);

  const handleDeleteConfirm = () => {
    deleteData(
      `${API_URL}/deleteDoctorById`,
      selectedItem._id,
      setError

    )
      .then(() => {
        setData((prevData) =>
          prevData.filter((item) => item._id !== selectedItem._id)
        );
        toast.success('Deleted successfully', { autoClose: 2000 })

      })
      .catch((error) => {
        setError(error);
        toast.error('Error deleteing data' , {autoClose: 2000})

      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(filteredData, currentPage, ITEMS_PER_PAGE);

  const fieldsToShow = {
    'Therapist First Name': 'receiverId.firstName',
    'Therapist Last Name': 'receiverId.lastName',
    'Therapist Email': 'receiverId.email',
    'Patient First Name': 'senderId.firstName',
    'Patient Last Name': 'senderId.lastName',
    'Patient Email': 'senderId.email',
    'Appointment Date': 'appointmentDate',
    'Status': 'status',
  };

  
  const handleDateFilter = (startDate, endDate) => {
    if (!startDate || !endDate) {
      setFilteredData(data); 
      return;
    }

    const filtered = data.filter((appointment) => {
      const appointmentDate = new Date(appointment.appointmentDate);
      return isWithinInterval(appointmentDate, { start: startDate, end: endDate });
    });

    setFilteredData(filtered);
    setCurrentPage(1); 
  };

  return (
    <div className="therapist-page">
      <div className="flex justify-between items-center text-[#1B1F2E] px-0 py-4 mb-2">
        <h2 className="text-[28px] font-semibold">Notification List</h2>
      </div>
      <div className="search-filter-container shadow-sm  justify-between  gap-5 mb-3 flex-wrap bg-white rounded-lg px-3 py-3 flex items-start min-h-[170px] h-[170px]">
      <DateRangePicker onDateChange={handleDateFilter} />

      </div>
      <div className="table-container">
      <Table
        columns={columns}
        data={currentData}
        showIndex={true}
        currentPage={currentPage}
        loading={loading}
        itemsPerPage={ITEMS_PER_PAGE}
        onDelete={handleOpenDeleteModal}
        onView={handleOpenViewModal}
        showEdit={false} 
        showForm={false}
      />
           {!loading && (
  <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    onPageChange={handlePageChange(setCurrentPage)}
  />
)}
      </div>

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      {selectedItem && (
        <ViewModal
          show={showViewModal}
          onHide={handleCloseViewModal}
          data={selectedItem}
          fields={fieldsToShow}
        />
      )}
    </div>
  );
};

export default Notification;
