import React from 'react';

const FormComponent = ({
  register,
  options,
  handleOptionChange,
  handleAddOption,
  handleRemoveOption,
  errors
}) => {
  return (
    <div className="space-y-6">
      {/* Question Label */}
      <div className="flex flex-col">
        <label className="text-gray-700 font-medium mb-2">Question Label</label>
        <input
          type="text"
          className="border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-gray-400 focus:outline-none"
          {...register('label')}
        />
        {errors.label && <p className="text-red-500 text-sm">{errors.label.message}</p>}
      </div>

      {/* Options Type */}
      <div className="flex flex-col">
        <label className="text-gray-700 font-medium mb-2">Options Type</label>
        <select
          className="border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-gray-400 focus:outline-none text-gray-800"
          {...register('optionsType')}
        >
          <option value="">Select Type</option>
          <option value="text">Text</option>
          <option value="radio">Radio</option>
          <option value="checkbox">Checkbox</option>
          <option value="dropdown">Dropdown</option>
        </select>
        {errors.optionsType && <p className="text-red-500 text-sm">{errors.optionsType.message}</p>}
      </div>

      {/* Options */}
      {options.length > 0 && (
        <div className="flex flex-col space-y-4">
          <label className="text-gray-700 font-medium">Options</label>
          {options.map((option, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                type="text"
                className="flex-grow border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-gray-400 focus:outline-none"
                placeholder="Label"
                value={option.label}
                onChange={(e) => handleOptionChange(index, 'label', e.target.value)}
              />
                      {errors.options && <p className="text-red-500 text-sm">{errors.options.message}</p>}

              <input
                type="text"
                className="flex-grow border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-gray-400 focus:outline-none"
                placeholder="Value"
                value={option.value}
                onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
              />
                      {errors.options && <p className="text-red-500 text-sm">{errors.options.message}</p>}

              <button
                type="button"
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
                onClick={() => handleRemoveOption(index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            className="bg-gray-800 text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 max-w-[180px] mb-3"
            onClick={handleAddOption}
          >
            Add Option
          </button>
        </div>
      )}
    </div>
  );
};

export default FormComponent;
