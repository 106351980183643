import React from 'react'
// import '../../styles/Register.css'
import RegistrationForm from '../../forms/RegistrationForm'
const Register = () => (
<div className='bg-white'>

<div className="container ">
        <div className="flex items-center justify-center ">
        <div className="w-full md:w-1/2 p-4">
  <div className="p-6 shadow-md rounded-lg bg-white">
    {/* <h2 className="text-center text-2xl font-semibold mb-4">Register</h2> */}
    <RegistrationForm />
  </div>
</div>

        </div>
    </div>
</div>
)


export default Register
