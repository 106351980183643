import React, { useState } from 'react';
import "../../styles/Search.css";

const Search = ({ searchFields, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="search-container mt-0">
      <input
        type="text"
        placeholder={`Search by ${searchFields.join(', ')}`}
        value={searchTerm}
        onChange={handleInputChange}
        className="search-input"
      />
    </div>
  );
};

export default Search;
