import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa"; // Import "Open Web" icon
import "./SportPsychologyTrainingsListing.css";

const API_URL = process.env.REACT_APP_API_URL;

const SportPsychologyTrainingView = () => {
  const [sports, setSports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSport = async () => {
      const response = await fetch(`${API_URL}/getAllSportPsychologyTraining`);
      const data = await response.json();
      console.log(data, "Fetched Sport Psychology Training");

      setSports(data?.data || []);
    };

    fetchSport();
  }, []);
  console.log(sports);
  return (
    <div className="blog-listing">
      <div className="blog-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {sports.length > 0 ? (
          sports.map((sport) => (
            <div
              key={sport._id}
              className="relative blog-card bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
              onClick={() => window.open(sport.link, "_self")} // Open link in a new tab
            >
              {/* Image with Open Web Icon */}
              <div className="relative">
                {/* <img src={webinar.image} alt={webinar.title} className="w-full h-48 object-cover" /> */}

                {/* Open Web Icon Overlay */}
                <FaExternalLinkAlt className="absolute top-2 left-2 w-10 h-10 text-gray-700 bg-white p-2 " />
              </div>

              {/* Content */}
              <div className="p-4 mt-4">
                <h2 className="text-lg font-bold ">{sport.title}</h2>
                <p className="text-gray-600">
                  {sport.description?.substring(0, 100)}...
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-center">
            Loading Sport Psychology Training...
          </p>
        )}
      </div>
    </div>
  );
};

export default SportPsychologyTrainingView;
