// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Modal.css */

.modal-body p {
    color: #000; /* Ensures text color is visible */
    font-size: 16px;
  }
  
  .modal-header .modal-title {
    color: #1B1F2E; /* Title color */
  }
  
  .modal-footer .btn-secondary {
    background-color: #1B1F2E; /* Button color */
    border-color: #1B1F2E;
  }
  
  .modal-footer .btn-danger {
    background-color: #FF7900; /* Button color */
    border-color: #FF7900;
  }
  
  .modal-footer .btn-danger:hover {
    background-color: #e67600; /* Darker shade on hover */
    border-color: #e67600;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/DeleteModal.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,WAAW,EAAE,kCAAkC;IAC/C,eAAe;EACjB;;EAEA;IACE,cAAc,EAAE,gBAAgB;EAClC;;EAEA;IACE,yBAAyB,EAAE,iBAAiB;IAC5C,qBAAqB;EACvB;;EAEA;IACE,yBAAyB,EAAE,iBAAiB;IAC5C,qBAAqB;EACvB;;EAEA;IACE,yBAAyB,EAAE,0BAA0B;IACrD,qBAAqB;EACvB","sourcesContent":["/* src/styles/Modal.css */\n\n.modal-body p {\n    color: #000; /* Ensures text color is visible */\n    font-size: 16px;\n  }\n  \n  .modal-header .modal-title {\n    color: #1B1F2E; /* Title color */\n  }\n  \n  .modal-footer .btn-secondary {\n    background-color: #1B1F2E; /* Button color */\n    border-color: #1B1F2E;\n  }\n  \n  .modal-footer .btn-danger {\n    background-color: #FF7900; /* Button color */\n    border-color: #FF7900;\n  }\n  \n  .modal-footer .btn-danger:hover {\n    background-color: #e67600; /* Darker shade on hover */\n    border-color: #e67600;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
