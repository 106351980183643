import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const FAQAdd = () => {
  const { id } = useParams(); // To get the faq ID from the URL for edit mode
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  // Effect to check if the ID exists and fetch the faq data for edit mode
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      // Fetch the faq details if ID is present
      axios
        .get(`${API_URL}/getFAQById/${id}`)
        .then((response) => {
          const faq = response.data.data;
          setQuestion(faq.question);
          setAnswer(faq.answer || ""); // Optional answer
        })
        .catch((error) => {
          console.error("Error fetching FAQ:", error);
          toast.error("Error fetching FAQ details", { autoClose: 2000 });
        });
    }
  }, [id]);

  // Handle form submission (both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure question and link are filled (required fields)
    if (!question) {
      toast.error("Question is a required field", { autoClose: 2000 });
      return;
    }

    try {
      const faqData = {
        question,
        answer,
      };

      // If in edit mode, update the existing faq
      if (isEditMode) {
        await axios.put(`${API_URL}/updateFAQ/${id}`, faqData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("FAQ updated successfully", { autoClose: 2000 });
      } else {
        // If in create mode, create a new faq
        await axios.post(`${API_URL}/createFAQ`, faqData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("FQA created successfully", { autoClose: 2000 });
      }

      // Redirect to the list of faqs after submission
      navigate("/faq");
    } catch (error) {
      console.error("Error saving FAQ :", error);
      toast.error("Error saving FAQ", { autoClose: 2000 });
    }
  };

  return (
    <div className="container mt-5">
      <h2>{isEditMode ? "Edit faq" : "Create New faq"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Question</label>
          <input
            type="text"
            className="form-control"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Answer</label>
          <textarea
            className="form-control"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isEditMode ? "Update FAQ" : "Save FAQ"}
        </button>
      </form>
    </div>
  );
};

export default FAQAdd;
