import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaUsers, FaUser, FaDashcube, FaRegUser } from "react-icons/fa"; // Import user-related icons
import { FaUserDoctor } from "react-icons/fa6";
import { IoIosNotifications } from "react-icons/io";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { TbLogs } from "react-icons/tb";

const links = [
  { to: "/home", label: "Dashboard", icon: <FaDashcube /> },
  {
    label: "Users",
    icon: <FaRegUser />,
    submenu: [
      { to: "/therapist", label: "Therapists", icon: <FaUserDoctor /> },
      { to: "/patient", label: "Clients", icon: <FaUser /> },
    ],
  },
  { to: "/doc-appointment", label: "Appointment", icon: <RiQuestionAnswerFill /> },
  { to: "/notification", label: "Notification", icon: <IoIosNotifications /> },
  { to: "/questionnaire", label: "Questionnare", icon: <TbLogs /> },
  { to: "/blogs", label: "Blogs", icon: <TbLogs /> },
];

const Sidebar = () => {
  const [openMenus, setOpenMenus] = useState({});
  const location = useLocation();

  const handleMenuToggle = (label) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const isSubmenuActive = (submenu) => {
    return submenu.some((subLink) => location.pathname === subLink.to);
  };

  return (
    <div className="w-64 h-screen bg-[#1c2434] overflow-y-auto pt-0  fixed top-0 left-0 shadow-lg left-sider-bar h-full z-[99]">
            <div className='logo-box mb-4 mt-3'><img src="./therapist-logo.png" alt="therapist-logo" className="max-h-[100px] object-contain w-full object-[50%_50%]" /></div>
      {links.map((link, index) => {
        const isSubmenu = Array.isArray(link.submenu);
        const isOpen = openMenus[link.label] || false;
        const isActive = location.pathname === link.to;
        const isUsersActive = isSubmenu && isSubmenuActive(link.submenu);

        return (
          <React.Fragment key={index}>
            {isSubmenu ? (
              <>
                <div
                  className={`flex items-center cursor-pointer p-3  ${
                    isUsersActive ? "bg-gray-600 text-white font-bold" : "text-gray-300"
                  }`}
                  onClick={() => handleMenuToggle(link.label)}
                >
                  {link.icon && <span className="mr-2">{link.icon}</span>}
                  {link.label}
                </div>
                <div className={`ml-6 flex-col ${isOpen ? "flex" : "hidden"}`}>
                  {link.submenu.map((subLink, subIndex) => {
                    const isSubmenuActive = location.pathname === subLink.to;

                    return (
                      <Link
                        key={subIndex}
                        className={`flex items-center p-3 rounded-md ${
                          isSubmenuActive
                            ? "bg-gray-600 text-white font-semibold"
                            : "text-gray-400 hover:bg-gray-600"
                        }`}
                        to={subLink.to}
                      >
                        {subLink.icon && <span className="mr-2">{subLink.icon}</span>}
                        {subLink.label}
                      </Link>
                    );
                  })}
                </div>
              </>
            ) : (
              <Link
                className={`flex items-center p-3 mb-2 rounded-md ${
                  isActive
                    ? ":bg-gray-600 text-white font-bold"
                    : "text-gray-300 hover:bg-gray-600"
                }`}
                to={link.to}
              >
                {link.icon && <span className="mr-2">{link.icon}</span>}
                {link.label}
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Sidebar;
