import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const getNestedValue = (obj, path) => {
  if (!obj) return 'N/A';
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const ViewModal = ({ show, onHide, data, fields }) => {
  if (!data) return null;

  const isObject = typeof data === 'object' && !Array.isArray(data);

  if (!isObject) {
    const mappedData = Object.keys(fields).reduce((acc, key, index) => {
      acc[key] = data[index] || 'N/A';
      return acc;
    }, {});
    data = mappedData;
  }

  return (
    <>
      <Modal show={show} onHide={onHide} dialogClassName="max-w-2xl mx-auto rounded-lg">
        <Modal.Header closeButton className="bg-gray-800 text-white p-4 rounded-t-lg">
          <Modal.Title className="text-xl font-semibold text-white">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-6 max-h-[500px] overflow-y-auto">
          <div className="details-container space-y-4">
            {Object.entries(fields).map(([label, path]) => (
              <div className="detail-item flex justify-between items-center py-2 border-b border-gray-200" key={label}>
                <span className="detail-label font-medium text-gray-800">{label}:</span>
                <span className="detail-value text-gray-600">
                  {label === 'Profile Image' ? (
                    getNestedValue(data, path) ? (
                      <img
                        src={getNestedValue(data, path)}
                        alt="Profile"
                        className="w-24 h-24 rounded-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null; 
                          e.target.src = '/path/to/default-image.jpg'; // Fallback if needed
                        }}
                      />
                    ) : (
                      'N/A'
                    )
                  ) : (
                    getNestedValue(data, path) || 'N/A'
                  )}
                </span>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-end p-4">
          <Button
            variant="secondary"
            onClick={onHide}
            className="bg-gray-600 hover:bg-gray-700 text-white font-semibold px-4 py-2 rounded-md transition duration-200"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModal;
