import React from 'react';
import { useFormContext } from '../context/FormContext';
import { Input } from '../components/commonComponent/FormInput';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RegistrationForm = () => {
  const methods = useFormContext();
  const { register } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const success = await register(data.username, data.email, data.password);
    if (success) {
      navigate('/login');
    } else {
      console.error('Registration failed');
    }
  };

  return (
    <div className="flex items-center w-full justify-center ">
    <div className="w-full  p-8 space-y-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-semibold text-center text-gray-700">Create an Account</h2>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-5">
      <Input
  label="Username"
  controlProp={{ name: 'username', control: methods.control }}
  placeholder="Enter your username"
  type="text"
  className="w-full px-4 py-3 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 bg-gray-50"
  labelClass="text-gray-700 font-medium mb-2"  
/>

        <Input
          label="Email"
          controlProp={{ name: 'email', control: methods.control }}
          placeholder="Enter your email"
          type="email"
          labelClass="text-gray-700 font-medium mb-2" 
          className="w-full px-4 py-3 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 bg-gray-50"
        />
        <Input
          label="Password"
          controlProp={{ name: 'password', control: methods.control }}
          placeholder="Enter your password"
          type="password"
          labelClass="text-gray-700 font-medium mb-2" 
          className="w-full px-4 py-3 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 bg-gray-50 text-gray-800"
        />
        <Input
          label="Confirm Password"
          controlProp={{ name: 'confirmPassword', control: methods.control }}
          placeholder="Confirm your password"
          type="password"
          labelClass="text-gray-700 font-medium mb-2" 
          className="w-full px-4 py-3 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 bg-gray-50"
        />
        <button
          type="submit"
          className="w-full mt-3 px-4 py-3 bg-gray-800 text-white rounded hover:bg-gray-700 transition"
        >
          Register
        </button>
      </form>
      <p className="text-center text-gray-600">
        Already have an account?{' '}
        <Link to="/" className="text-blue-500 hover:underline">
          Login
        </Link>
      </p>
    </div>
  </div>
  
  
  );
};

export default RegistrationForm;
