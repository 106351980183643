import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import FormComponent from '../../components/commonComponent/QuestionnareForm';
import { questionnaireSchema } from '../../utils/validationSchema';

const API_URL = process.env.REACT_APP_API_URL;

const UpdateQuestionnare = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [options, setOptions] = useState([{ label: '', value: '' }]);

  // Initialize useForm with yupResolver and questionnaireSchema
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(questionnaireSchema),
  });

  // Fetch the existing question by ID
  useEffect(() => {
    axios.get(`${API_URL}/getQuestionById/${id}`)
      .then(response => {
        const question = response.data;
        setValue('label', question.label);
        setValue('optionsType', question.optionsType);
        setValue('required', question.required);
        setOptions(question.options);
      })
      .catch(error => {
        console.error('Error fetching question data:', error);
        toast.error('Failed to load question data', { autoClose: 2000 });
      });
  }, [id, setValue]);

  const handleOptionChange = (index, key, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][key] = value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { label: '', value: '' }]);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = options.filter((_, idx) => idx !== index);
    setOptions(updatedOptions);
  };

  const onSubmit = async (data) => {
    const updatedQuestion = {
      ...data,
      options,  // Include options in the payload
    };

    try {
      await axios.put(`${API_URL}/updateQuestion/${id}`, updatedQuestion);
      toast.success('Question updated successfully', { autoClose: 2000 });
      navigate('/questionnaire'); // Redirect to the questionnaire list
    } catch (error) {
      console.error('Error updating question:', error);
      toast.error('Failed to update question', { autoClose: 2000 });
    }
  };

  const handleClose = () => {
    navigate('/questionnare');
  };

  return (
    <div className="mx-auto p-6 border border-gray-300 rounded-lg bg-white">
      <div className="form-header flex justify-between items-center mb-4 border-b border-gray-700 pb-3">
        <h2 className="form-title text-2xl font-bold text-black">Update Questionnaire</h2>
        <button 
          className="form-close-btn text-gray-500 hover:text-gray-700 text-2xl" 
          onClick={handleClose}
        >
          &times;
        </button>
      </div>
      {/* Pass register, options, and errors to the form component */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormComponent
          register={register}
          options={options}
          handleOptionChange={handleOptionChange}
          handleAddOption={handleAddOption}
          handleRemoveOption={handleRemoveOption}
          errors={errors}
        />
        <button 
          type="submit"
          className="bg-gray-800 text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 mb-3"
        >
          Update Question
        </button>
      </form>
    </div>
  );
};

export default UpdateQuestionnare;
