// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the layout */
.layout-container {
  display: flex;
  height: auto;
  margin-top: 60px; /* Height of the Header */
}

/* Main content area styles */
.main-content {
  flex-grow: 1;
  padding: 20px 50px;
  margin-left: auto; 
  max-width: calc(100%  -  240px);
}`, "",{"version":3,"sources":["webpack://./src/styles/Layout.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB,EAAE,yBAAyB;AAC7C;;AAEA,6BAA6B;AAC7B;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;AACjC","sourcesContent":["/* Container for the layout */\n.layout-container {\n  display: flex;\n  height: auto;\n  margin-top: 60px; /* Height of the Header */\n}\n\n/* Main content area styles */\n.main-content {\n  flex-grow: 1;\n  padding: 20px 50px;\n  margin-left: auto; \n  max-width: calc(100%  -  240px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
