// src/components/ErrorDiv.js
import React from 'react';

const ErrorDiv = ({ error, errorAlignEnd, classes = "" }) => (
  <p className={`text-danger text-xs italic first-letter:uppercase ${errorAlignEnd ? "text-end " : ""}${classes}`}>
    {error}
  </p>
);

export default ErrorDiv;
