import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaYoutube, FaSpotify } from "react-icons/fa"; // Import icons
import './PodcastListing.css';

const API_URL = process.env.REACT_APP_API_URL;

const PodcastWebView = () => {
  const [podcast, setPodcasts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPodcasts = async () => {
      const response = await fetch(`${API_URL}/getAllPodcasts`);
      const data = await response.json();
      console.log(data, "Fetched Podcasts");

      if (data?.data?.length === 1) {
        navigate(`/podcast/${data?.data[0]?._id}`);
      }
      setPodcasts(data?.data || []);
    };

    fetchPodcasts();
  }, []);

  // Function to determine the link type
  const getPodcastType = (url) => {
    try {
      const parsedUrl = new URL(url);

      if (parsedUrl.hostname.includes("youtu.be") || parsedUrl.hostname.includes("youtube.com")) {
        return "youtube";
      } else if (parsedUrl.hostname.includes("spotify.com")) {
        return "spotify";
      }
    } catch (error) {
      console.error("Invalid link:", url);
    }
    return "unknown";
  };

  return (
    <div className="blog-listing">
      <div className="blog-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {podcast.length > 0 ? (
          podcast.map((podcast) => {
            const podcastType = podcast?.link ? getPodcastType(podcast.link) : "unknown";

            return (
              <div className="relative blog-card bg-white rounded-lg shadow-lg overflow-hidden" key={podcast._id}>
                {/* Image with Icon Overlay */}
                <div className="relative">
  {/* Image */}
  {/* <img src={Podcast.image} alt={Podcast.title} className="w-full h-48 object-cover" /> */}

  {/* Icon positioned above the card */}
  {podcastType === "youtube" && (
    <FaYoutube className=" w-full h-48 top-2 left-2 w-12 h-12 text-red-600  p-1" />
  )}
  {podcastType === "spotify" && (
    <FaSpotify className="w-full h-48 top-2 left-2 w-12 h-12 text-green-500  p-1 " />
  )}
</div>


                {/* Content */}
                <div className="p-4">
                  <h2 className="text-lg font-bold">{podcast.title}</h2>
                  <p className="text-gray-600">
                    {podcast.description?.substring(0, 100)}...
                  </p>
                  <Link to={`/podcast/${podcast._id}`} className="text-blue-500 mt-2 inline-block">
                    Read More
                  </Link>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-gray-600 text-center">Loading podcasts...</p>
        )}
      </div>
    </div>
  );
};

export default PodcastWebView;
