import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormComponent from '../../components/commonComponent/QuestionnareForm';
import { questionnaireSchema } from '../../utils/validationSchema';

const API_URL = process.env.REACT_APP_API_URL;

const AddQuestionnaire = () => {
  const [options, setOptions] = useState([{ label: '', value: '' }]);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(questionnaireSchema),
  });

  const handleAddOption = () => {
    setOptions([...options, { label: '', value: '' }]);
  };

  const handleRemoveOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const onSubmit = async (data) => {
    const newQuestion = {
      ...data,
      options: data.optionsType === 'text' ? [] : options,
    };

    try {
      await axios.post(`${API_URL}/createQuestion`, newQuestion);
      toast.success('Question added successfully', { autoclose: 1000 });
      setTimeout(() => navigate('/questionnaire'), 1000);
    } catch (error) {
      toast.error('Error adding data', { autoclose: 2000 });
      console.error('There was an error creating the question!', error);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <div className="form-header flex justify-between items-center mb-4 border-b border-gray-700 pb-3">
        <h2 className="form-title text-2xl font-bold text-black">Add Question</h2>
        <button className="form-close-btn text-gray-400 hover:text-red-500 transition duration-200" onClick={() => navigate('/questionnaire')}>
          &times;
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormComponent
          register={register}
          options={options}
          handleOptionChange={handleOptionChange}
          handleAddOption={handleAddOption}
          handleRemoveOption={handleRemoveOption}
          errors={errors}
        />
        <button type="submit" className="bg-gray-800 text-white font-normal py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200">
          Add Question
        </button>
      </form>
    </div>
  );
};

export default AddQuestionnaire;
