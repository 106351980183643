// src/utils/validationSchema.js
import * as yup from 'yup';

export const registrationSchema = yup.object().shape({
  username: yup.string().required('Username name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});


export const patientSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'), 
  email: yup.string().email('Invalid email format').required('Email is required'),
  state: yup.string().required('State is required'),  
  city: yup.string().required('City is required'), 
  password: yup.string().required('Password is required'), 

});

export const therapistSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'), 
  email: yup.string().email('Invalid email format').required('Email is required'),
  state: yup.string().required('State is required'),  
  city: yup.string().required('City is required'), 
  password: yup.string()
  .matches(
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    "Password must have at least 8 characters, one uppercase letter, one number, and one special character"
  )
  .required("Password is required"),});

export const questionnaireSchema = yup.object().shape({
  label: yup.string().required('Question label is required'),
  optionsType: yup.string().required('Options type is required'),
  options: yup.string().required('Atleast one option is required')

});


