import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const WebinarDetails = () => {
  const { id } = useParams();
  const [webinar, setWebinar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWebinarDetails = async () => {
      try {
        const response = await fetch(
          `https://therapist.api.ithemes.xyz/getWebinarById/${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("API Response:", data);

        setWebinar(data.data || {});
        setLoading(false);
      } catch (error) {
        setError("Error fetching webinar details");
        setLoading(false);
      }
    };

    fetchWebinarDetails();
  }, [id]);

  if (loading)
    return (
      <div className="text-center text-xl font-medium text-gray-700">
        Loading...
      </div>
    );
  if (error)
    return (
      <div className="text-center text-xl font-medium text-red-500">
        {error}
      </div>
    );

  // Function to determine the link type
  const getVideoType = (url) => {
    try {
      const parsedUrl = new URL(url);

      if (parsedUrl.hostname.includes("youtu.be") || parsedUrl.hostname.includes("youtube.com")) {
        return "youtube";
      } else if (parsedUrl.hostname.includes("spotify.com")) {
        return "spotify";
      }
    } catch (error) {
      console.error("Invalid link:", url);
    }
    return "unknown";
  };

  const videoType = webinar?.link ? getVideoType(webinar.link) : "unknown";

  return (
    <div className="container mx-auto p-6 lg:px-16">
      {webinar ? (
        <div>
          <h1 className="text-lg font-bold text-gray-900 mb-6">
            {webinar.title || "No Title Available"}
          </h1>

          {/* Video / Audio Section */}
          {videoType !== "unknown" && (
            <div className="my-4">
              <h3 className="text-lg font-semibold text-gray-800">
                Webinar Media:
              </h3>

              {videoType === "youtube" && (() => {
                let videoId = "";
                try {
                  const url = new URL(webinar.link);
                  if (url.hostname.includes("youtu.be")) {
                    videoId = url.pathname.substring(1).split("?")[0];
                  } else if (url.hostname.includes("youtube.com")) {
                    videoId = url.searchParams.get("v");
                  }
                } catch (error) {
                  console.error("Invalid YouTube link:", webinar.link);
                }

                return videoId ? (
                  <iframe
                    width="100%"
                    height="350"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube Video"
                    className="rounded-lg shadow-lg"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <p className="text-red-500">Video preview not available</p>
                );
              })()}

              {videoType === "spotify" && (() => {
                let spotifyEmbedUrl = "";
                try {
                  const url = new URL(webinar.link);
                  if (url.hostname.includes("spotify.com")) {
                    spotifyEmbedUrl = webinar.link.replace(
                      "open.spotify.com",
                      "embed.spotify.com"
                    );
                  }
                } catch (error) {
                  console.error("Invalid Spotify link:", webinar.link);
                }

                return spotifyEmbedUrl ? (
                  <a
                    href={webinar.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block relative group"
                  >
                    <iframe
                      src={spotifyEmbedUrl}
                      width="100%"
                      height="152"
                      frameBorder="0"
                      allow="encrypted-media"
                      className="rounded-lg transition-transform group-hover:scale-105"
                    ></iframe>
                    <span className="absolute inset-0 bg-opacity-10 group-hover:bg-opacity-20 transition"></span>
                  </a>
                ) : (
                  <p className="text-red-500">Spotify preview not available</p>
                );
              })()}
            </div>
          )}

          {/* Description Section */}
          <div className="prose max-w-full text-gray-700 mt-6">
            <p className="text-lg mb-4">
              {webinar.description || "No description available"}
            </p>
          </div>
        </div>
      ) : (
        <div className="text-center text-xl text-gray-700">Webinar not found</div>
      )}
    </div>
  );
};

export default WebinarDetails;
    