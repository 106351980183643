import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const StoresAdd = () => {
  const { id } = useParams(); // To get the store ID from the URL for edit mode
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  // Effect to check if the ID exists and fetch the store data for edit mode
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      // Fetch the store details if ID is present
      axios
        .get(`${API_URL}/getStoreById/${id}`)
        .then((response) => {
          const store = response.data.data;
          setTitle(store.title);
          setDescription(store.description || ""); // Optional description
          setLink(store.link);
        })
        .catch((error) => {
          console.error("Error fetching store:", error);
          toast.error("Error fetching store details", { autoClose: 2000 });
        });
    }
  }, [id]);

  // Handle form submission (both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure title and link are filled (required fields)
    if (!title || !link) {
      toast.error("Title and Link are required fields", { autoClose: 2000 });
      return;
    }

    try {
      const storeData = {
        title,
        description,
        link,
      };

      // If in edit mode, update the existing store
      if (isEditMode) {
        await axios.put(`${API_URL}/updateStore/${id}`, storeData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Store updated successfully", { autoClose: 2000 });
      } else {
        // If in create mode, create a new store
        await axios.post(`${API_URL}/createStore`, storeData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Store created successfully", { autoClose: 2000 });
      }

      // Redirect to the list of stores after submission
      navigate("/stores");
    } catch (error) {
      console.error("Error saving store:", error);
      toast.error("Error saving store", { autoClose: 2000 });
    }
  };

  return (
    <div className="container mt-5">
      <h2>{isEditMode ? "Edit store" : "Create New store"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description (Optional)</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Link</label>
          <input
            type="url"
            className="form-control"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isEditMode ? "Update store" : "Save store"}
        </button>
      </form>
    </div>
  );
};

export default StoresAdd;
