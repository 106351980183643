import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import axios from 'axios';

// Custom toolbar options for embedding YouTube and media options

const API_URL = process.env.REACT_APP_API_URL;

const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link', 'image', 'video'],
    ['clean'],
    [{ 'align': [] }]
  ]
};

const BlogEditor = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const quillRef = useRef(null); // Reference to Quill editor

  // Handle file uploads
  const handleFileUpload = (file, type) => {
    if (type === 'image') {
      setImageFile(file);
    } else if (type === 'video') {
      setVideoFile(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('content', content);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
    
    // Append image and video files if they exist
    if (imageFile) {
      formData.append('image', imageFile);
    }
    if (videoFile) {
      formData.append('video', videoFile);
    }

    try {
      const response = await axios.post(`${API_URL}/createBlog`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Blog saved successfully', response.data);
    } catch (error) {
      console.error('Error saving blog', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Create New Blog</h2>
      <form onSubmit={handleSubmit}>
        {/* Title */}
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        {/* Description */}
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        {/* Content (Quill WYSIWYG Editor) */}
        <div className="form-group">
          <label>Content</label>
          <ReactQuill
            ref={quillRef}
            value={content}
            onChange={setContent}
            modules={modules}
          />
        </div>

        {/* File Uploads */}
        <div className="form-group">
          <label>Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload(e.target.files[0], 'image')}
          />
        </div>
        <div className="form-group">
          <label>Upload Video</label>
          <input
            type="file"
            accept="video/*"
            onChange={(e) => handleFileUpload(e.target.files[0], 'video')}
          />
        </div>

        {/* SEO Metadata */}
        <h3>Metadata</h3>
        <div className="form-group">
          <label>Meta Title</label>
          <input
            type="text"
            className="form-control"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Meta Description</label>
          <textarea
            className="form-control"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Meta Keywords</label>
          <input
            type="text"
            className="form-control"
            value={metaKeywords}
            onChange={(e) => setMetaKeywords(e.target.value)}
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">Save Blog</button>
      </form>
    </div>
  );
};

export default BlogEditor;
